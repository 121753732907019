import React, { Component } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Fragment, useState, useRef, useEffect } from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { getCommonwidgetData, getDomainScoreCard, getCommonwidgetData1, getwidget1Data, getwidget2Data, getwidget3Data, getwidget4Data, getwidget5Data, getwidget6Data, getwidget7Data, getUserScoreCard, getCalenderdata, getCompfielaw, savewidgetdata } from './../../src/Store/Action/Dashboard';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Card, Table } from 'antd';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment/moment';
import { PushpinOutlined, CloseOutlined, MinusOutlined, ExpandOutlined } from '@ant-design/icons';
import { TOGGLE_COMMON_LOADER } from './../Store/types/index';

const mapStateToProps = (state) => ({
    widgetsdata: state.Widgetscharts.widgetsChartsData.commonwidgetsdata,
    widgetsdata1: state.Widgetscharts.widgetsChartsData.commonwidgetsdata1,
    widgetComplianceStatus: state.Widgetscharts.widgetsChartsData.widgetsdata1,
    widgetComplianceTrend: state.Widgetscharts.widgetsChartsData.widgetsdata2,
    widgetComparativeChart: state.Widgetscharts.widgetsChartsData.widgetsdata3,
    widgetRisk: state.Widgetscharts.widgetsChartsData.widgetsdata4,
    WidgetTaskdistribution: state.Widgetscharts.widgetsChartsData.widgetsdata5,
    widgetoverdue: state.Widgetscharts.widgetsChartsData.widgetsdata6,
    WidgetCriticality: state.Widgetscharts.widgetsChartsData.widgetsdata7,
    WidgetDomainScoreCard: state.Widgetscharts.widgetsChartsData.widgetsdomaindata,
    WidgetUserScoreCard: state.Widgetscharts.widgetsChartsData.widgetuserscorecard,
    WidgetCalenderData: state.Widgetscharts.widgetsChartsData.widgetCalenderdata,
    WidgetCompfieData: state.Widgetscharts.widgetsChartsData.widgetCompfielaw,
})
const Dashboard = ({
    getCommonwidgetData,
    getCommonwidgetData1,
    getDomainScoreCard,
    getUserScoreCard,
    getwidget1Data,
    getwidget2Data,
    getwidget3Data,
    getwidget4Data,
    getwidget5Data,
    getwidget6Data,
    getwidget7Data,
    getCalenderdata,
    getCompfielaw,
    savewidgetdata,
    widgetsdata,
    widgetsdata1,
    widgetoverdue,
    widgetComplianceStatus,
    widgetComplianceTrend,
    widgetComparativeChart,
    widgetRisk,
    WidgetCriticality,
    WidgetTaskdistribution,
    WidgetDomainScoreCard,
    WidgetUserScoreCard,
    WidgetCalenderData
}) => {
    console.log(widgetsdata1, "widgetsdata1widgetsdata1");
    console.log(widgetComplianceTrend, "widgetComplianceTrend");
    console.log(widgetComparativeChart, "widgetComparativeChart");
    const commonLoading = useSelector((state) => state.Login.commonLoading);
    console.log(commonLoading, "commonLoading");
    const _ = require("lodash");
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionvalue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionvalue)
    const sessionArr = sessionParsedValue.entity_info
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    const login = callername.login_response.menu.Static[0];
    const paramid = location.pathname;
    // const [paramid, setParamId] = useState(location.pathname)
    console.log(paramid, "paramidparamid");
    const [entityData, setEntityData] = useState([])
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [filteredArray, setFilteredArray] = useState([])
    console.log(filteredArray, "filteredArray");
    const [domainfilterdata, setDomainfilterdata] = useState([])
    const [countryDomain, setCountryDomain] = useState([])
    console.log(countryDomain, "countryDomain");
    const [widgetsfinallist, setWidgetsfinallist] = useState([])
    console.log(widgetsfinallist, "widge44444st55555");
    const [widgetinfolist, setWidgetinfolist] = useState([])
    const [widgetinfoname, setWidgetinfoname] = useState([])
    const [widgetsize, setWidgetistsize] = useState([])
    console.log(widgetsize, "widgetsizewidgetsize");
    const [eventData, setEventData] = useState([])
    const localizer = momentLocalizer(moment);
    const [toggleButton, setToggleButton] = useState(true)
    const [compliancestatus, setcompliancestatus] = useState(true)
    const [compliancetrend, setcompliancetrend] = useState(true)
    const [comparativetrend, setcomparativetrend] = useState(true)
    const [riskchart, setriskchart] = useState(true)
    const [overdue, setoverdue] = useState(true)
    const [taskdistribution, settaskdistribution] = useState(true)
    const [criticality, setcriticality] = useState(true)
    const [domainscore, setdomainscore] = useState(true)
    const [assigneescore, setassigneescore] = useState(true)
    const [compfielaw, setcompfielaw] = useState(true)
    const [calenderview, setcalenderview] = useState(true)
    const [resizecomptrend, setresizecomptrend] = useState(false)
    const [resizecomparative, setresizecomparative] = useState(false)
    const [resizecriticality, setresizecriticality] = useState(false)
    const [resizerisk, setresizerisk] = useState(false)
    const [resizeoverdue, setresizeoverdue] = useState(false)
    const [resizetdistribution, setresizetdistribution] = useState(false)
    const [resizedomain, setresizedomain] = useState(false)
    const [resizeassignee, setresizeassignee] = useState(false)
    const [resizecalender, setresizecalender] = useState(false)
    const [resizestate, setresizestate] = useState(false)
    const [resizecompstatus, setresizecompstatus] = useState(false)
    const [finalwidgetAPI, setfinalwidgetAPI] = useState([])
    const [totalvalue, setTotalvalue] = useState([])
    var [count, setcount] = useState(1)
    const [compstatussize, setcompstatussize] = useState([])
    console.log(compstatussize, "compstatussizecompstatussize");
    const [comptrendsize, setcomptrendsize] = useState([])
    const [comparativetrendsize, setcomparativetrendsize] = useState([])
    const [riskchartsize, setriskchartsize] = useState([])
    const [overduesize, setoverduesize] = useState([])
    const [taskdistributionsize, settaskdistributionsize] = useState([])
    const [criticalitysize, setcriticalitysize] = useState([])
    const [domaincardsize, setdomaincardsize] = useState([])
    const [usercardsize, setusercardsize] = useState([])
    const [calendersize, setcalendersize] = useState([])

    useEffect(() => {
        let temp = totalvalue
        if (Object.keys(widgetoverdue).length) {
            temp.push(widgetoverdue)
        } if (Object.keys(widgetComplianceStatus).length) {
            temp.push(widgetComplianceStatus)
        } if (Object.keys(widgetComplianceTrend).length) {
            temp.push(widgetComplianceTrend)
        } if (Object.keys(widgetComparativeChart).length) {
            temp.push(widgetComparativeChart)
        } if (Object.keys(widgetRisk).length) {
            temp.push(widgetRisk)
        } if (Object.keys(WidgetCriticality).length) {
            temp.push(WidgetCriticality)
        } if (Object.keys(WidgetTaskdistribution).length) {
            temp.push(WidgetTaskdistribution)
        } if (Object.keys(WidgetDomainScoreCard).length) {
            temp.push(WidgetDomainScoreCard)
        } if (Object.keys(WidgetUserScoreCard).length) {
            temp.push(WidgetUserScoreCard)
        } if (Object.keys(WidgetCalenderData).length) {
            temp.push(WidgetCalenderData)
        }
        let unique = []
        if (temp && temp.length > 0) {
            unique = _.uniqBy(temp, function (e) {
                return e.chart_title;
            });
        }
        setTotalvalue(unique)
    }, [widgetoverdue, widgetComplianceStatus, widgetComparativeChart, widgetComplianceTrend, widgetRisk, WidgetCriticality, WidgetTaskdistribution, WidgetDomainScoreCard, WidgetUserScoreCard, WidgetCalenderData, widgetinfolist])

    // useEffect(() => {
    //     let cou = count
    //     console.log(widgetinfolist.length, "widgetinfolist.length");
    //     console.log(totalvalue.length,"totallength");
    //     // if(widgetinfolist && widgetinfolist.length == totalvalue && totalvalue.length ){
    //     if (widgetinfolist && widgetinfolist.length > 0) {
    //         console.log("trueelength");
    //         if (count == 1) {
    //             console.log("sssssssssssss");
    //             dispatch({
    //                 type: TOGGLE_COMMON_LOADER
    //             });
    //             setcount(2)
    //         }
    //     }
    // }, [totalvalue, widgetinfolist, count])

    // useEffect(() => {

    //     if (widgetinfolist && widgetinfolist.length > 0) {
    //         console.log("trueeeeee");
    //         dispatch({
    //             type: TOGGLE_COMMON_LOADER
    //         });
    //     }
    // }, [widgetinfolist])

    useEffect(() => {
        if (WidgetDomainScoreCard && WidgetDomainScoreCard.widget_data && WidgetDomainScoreCard.widget_data.length > 0) {
            setFilteredArray(WidgetDomainScoreCard.widget_data)
        }
    }, [WidgetDomainScoreCard])

    useEffect(() => {
        if (WidgetUserScoreCard && WidgetUserScoreCard.widget_data && WidgetUserScoreCard.widget_data.length > 0) {
            console.log(WidgetUserScoreCard.widget_data, "44444444");
            setDomainfilterdata(WidgetUserScoreCard.widget_data)
        }
    }, [WidgetUserScoreCard])

    // useEffect(() => {
    //     {
    //         var the_arr = location.pathname.split('/');
    //         the_arr.pop();
    //         let a = the_arr.join('/')
    //         setParamId(a)
    //     }
    // }, [location]);

    useEffect(() => {
        // if (localStorage.getItem('SelectedEntity') == "All Legal Entity") {
        let id = sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
            return item.le_id
        })
        setEntityData(id)
        // }
    }, [])

    useEffect(() => {
        if (widgetinfoname && widgetinfoname.length > 0) {
            console.log(widgetinfoname[widgetinfoname.length - 1], "lengthhhhhh");
            setfinalwidgetAPI(widgetinfoname[widgetinfoname.length - 1])
        }
    }, [widgetinfoname])

    useEffect(() => {
        if (widgetsdata && widgetsdata.d_info && widgetsdata.d_info.length > 0) {
            let tempArr = []
            let tempIds = []
            for (let i in widgetsdata.d_info) {
                tempIds.push(widgetsdata.d_info[i].d_id)
                let dmonth = Object.values(widgetsdata && widgetsdata.d_months)
                let filteredDomainMonth = _.find(dmonth[0], { d_name: widgetsdata.d_info[i].d_name })
                console.log(filteredDomainMonth, "filteredDomainMonth");
                tempArr.push({
                    'd_id': widgetsdata.d_info[i].d_id,
                    'c_id': widgetsdata.d_info[i].c_id,
                    "month_from": 1,
                    "month_to": 1
                    // "month_from": ((filteredDomainMonth.m_name_from && filteredDomainMonth.m_name_from === 'January' ? 1 : (filteredDomainMonth.m_name_from === 'February' ? 2 : (filteredDomainMonth.m_name_from === 'March' ? 3 : (filteredDomainMonth.m_name_from === 'April' ? 4 : (filteredDomainMonth.m_name_from === 'May' ? 5 : (filteredDomainMonth.m_name_from === 'June' ? 6 : (filteredDomainMonth.m_name_from === 'July' ? 7 : (filteredDomainMonth.m_name_from === 'August' ? 8 : (filteredDomainMonth.m_name_from === 'September' ? 9 : (filteredDomainMonth.m_name_from === 'October' ? 10 : (filteredDomainMonth.m_name_from === 'November' ? 11 : 12)))))))))))),
                    // "month_to": ((filteredDomainMonth.m_name_to && filteredDomainMonth.m_name_to === 'January' ? 1 : (filteredDomainMonth.m_name_to === 'February' ? 2 : (filteredDomainMonth.m_name_to === 'March' ? 3 : (filteredDomainMonth.m_name_to === 'April' ? 4 : (filteredDomainMonth.m_name_to === 'May' ? 5 : (filteredDomainMonth.m_name_to === 'June' ? 6 : (filteredDomainMonth.m_name_to === 'July' ? 7 : (filteredDomainMonth.m_name_to === 'August' ? 8 : (filteredDomainMonth.m_name_to === 'September' ? 9 : (filteredDomainMonth.m_name_to === 'October' ? 10 : (filteredDomainMonth.m_name_to === 'November' ? 11 : 12))))))))))))
                })
            }
            setCountryDomain(tempArr)
        }
    }, [widgetsdata])

    useEffect(() => {
        if (widgetsdata1 && widgetsdata1.widget_info) {
            let temp_uid = []
            setWidgetistsize(widgetsdata1.widget_info)
            for (let i in widgetsdata1.widget_info) {
                temp_uid.push(widgetsdata1.widget_info[i].w_id)
            }
            setWidgetinfolist([...new Set(temp_uid)])
        }
    }, [widgetsdata1])

    useEffect(() => {
        if (widgetsdata1 && widgetsdata1.widget_list) {
            let widgetinfos = widgetsdata1 && widgetsdata1.widget_list.filter((item) => {
                return widgetinfolist.includes(item.w_id)
            })
            let temp_name = []
            for (let i in widgetinfos) {
                temp_name.push(widgetinfos[i].w_name)
            }
            setWidgetinfoname([...new Set(temp_name)])

            setWidgetsfinallist(widgetinfos)
        }
    }, [widgetsdata1, widgetinfolist])

    useEffect(() => {
        if (widgetsize && widgetsize.length > 0) {
            let compstatussize = widgetsize.filter((item) => {
                return item.w_id === 1
            })
            setcompstatussize(compstatussize)
            let comptrendsize = widgetsize.filter((item) => {
                return item.w_id === 10
            })
            console.log(comptrendsize, "comptrendsizecomptrendsize");
            setcomptrendsize(comptrendsize)
            let comparativetrendsize = widgetsize.filter((item) => {
                return item.w_id === 4
            })
            console.log(comparativetrendsize, "comptrendsizecomptrendsize");
            setcomparativetrendsize(comparativetrendsize)
            let risksize = widgetsize.filter((item) => {
                return item.w_id === 5
            })
            console.log(risksize, "comptrendsizecomptrendsize");
            setriskchartsize(risksize)
            let overduesize = widgetsize.filter((item) => {
                return item.w_id === 3
            })
            console.log(overduesize, "comptrendsizecomptrendsize");
            setoverduesize(overduesize)
            let criticalitysize = widgetsize.filter((item) => {
                return item.w_id === 12
            })
            console.log(criticalitysize, "comptrendsizecomptrendsize");
            setcriticalitysize(criticalitysize)
            let taskdistrisize = widgetsize.filter((item) => {
                return item.w_id === 11
            })
            console.log(taskdistrisize, "comptrendsizecomptrendsize");
            settaskdistributionsize(taskdistrisize)
            let domainsize = widgetsize.filter((item) => {
                return item.w_id === 7
            })
            console.log(domainsize, "comptrendsizecomptrendsize");
            setdomaincardsize(domainsize)
            let assigneesize = widgetsize.filter((item) => {
                return item.w_id === 6
            })
            console.log(assigneesize, "comptrendsizecomptrendsize");
            setusercardsize(assigneesize)
            let calendersize = widgetsize.filter((item) => {
                return item.w_id === 2
            })
            console.log(calendersize, "comptrendsizecomptrendsize");
            setcalendersize(calendersize)
        }
    }, [widgetsize])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetChartFilters",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                        }
                    ]
                }
            ]
            console.log(paramid, "paramid000000000000");
            if (paramid != "") {
                getCommonwidgetData({
                    payload: payload,
                    paramid: paramid
                })
            }
        }
    }, [isAuth,entityid, entityData])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUserWidgetData",
                        {}
                    ]
                }
            ]
            if (paramid != "") {
                getCommonwidgetData1({
                    payload: payload,
                    paramid: paramid
                })
            }
        }
    }, [isAuth,entityid,entityData])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceChart",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                            "country_domains": countryDomain
                        }
                    ]
                }
            ]
            if (widgetsfinallist && widgetsfinallist.length > 0) {
                for (let i in widgetsfinallist) {
                    if (widgetsfinallist[i].w_name == 'Compliance Status') {
                        getwidget1Data({
                            payload: payload,
                            caller: login.form_url
                        })
                    }
                }
            }
        }
    }, [isAuth, entityid, entityData, widgetsfinallist])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceTrend",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                            "country_domains": countryDomain
                        }
                    ]
                }
            ]
            if (widgetsfinallist && widgetsfinallist.length > 0) {
                for (let i in widgetsfinallist) {
                    if (widgetsfinallist[i].w_name == 'Compliance Trend') {
                        getwidget2Data({
                            payload: payload,
                            caller: login.form_url,
                        })
                    }
                }
            }
        }
    }, [isAuth,entityid, entityData, widgetsfinallist])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComparativeChart",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                            "country_domains": countryDomain
                        }
                    ]
                }
            ]
            if (widgetsfinallist && widgetsfinallist.length > 0) {
                for (let i in widgetsfinallist) {
                    if (widgetsfinallist[i].w_name == 'Comparative Trend') {
                        getwidget3Data({
                            payload: payload,
                            caller: login.form_url,
                        })
                    }
                }
            }
        }
    }, [isAuth, entityid, entityData, widgetsfinallist])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetRiskChart",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                            "country_domains": countryDomain
                        }
                    ]
                }
            ]
            if (widgetsfinallist && widgetsfinallist.length > 0) {
                for (let i in widgetsfinallist) {
                    if (widgetsfinallist[i].w_name == 'Risk Chart') {
                        getwidget4Data({
                            payload: payload,
                            caller: login.form_url
                        })
                    }
                }
            }
        }
    }, [isAuth, entityid, entityData, widgetsfinallist])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetTaskCriticality",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                            "country_domains": countryDomain
                        }
                    ]
                }
            ]
            if (widgetsfinallist && widgetsfinallist.length > 0) {
                for (let i in widgetsfinallist) {
                    if (widgetsfinallist[i].w_name == 'Criticality') {
                        getwidget7Data({
                            payload: payload,
                            caller: login.form_url
                        })
                    }
                }
            }
        }
    }, [isAuth, entityid, entityData, widgetsfinallist])
    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetTaskDistributionWidget",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                            "country_domains": countryDomain
                        }
                    ]
                }
            ]
            if (widgetsfinallist && widgetsfinallist.length > 0) {
                for (let i in widgetsfinallist) {
                    if (widgetsfinallist[i].w_name == 'Task Distribution') {
                        getwidget5Data({
                            payload: payload,
                            caller: login.form_url,
                        })
                    }
                }
            }
        }
    }, [isAuth, entityid, entityData, widgetsfinallist])


    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotCompliedChart",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                            "country_domains": countryDomain
                        }
                    ]
                }
            ]
            if (widgetsfinallist && widgetsfinallist.length > 0) {
                for (let i in widgetsfinallist) {
                    if (widgetsfinallist[i].w_name == 'Over Due Chart') {
                        getwidget6Data({
                            payload: payload,
                            caller: login.form_url
                        })
                    }
                }
            }
        }
    }, [isAuth,entityid, entityData, widgetsfinallist])
    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUserScoreCard",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                            "country_domains": countryDomain
                        }
                    ]
                }
            ]
            if (widgetsfinallist && widgetsfinallist.length > 0) {
                for (let i in widgetsfinallist) {
                    if (widgetsfinallist[i].w_name == 'Assignee Scorecard') {
                        getUserScoreCard({
                            payload: payload,
                            caller: login.form_url
                        })
                    }
                }
            }
        }
    }, [isAuth, entityid, entityData, widgetsfinallist])


    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        'GetDomainScoreCard', {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                        }
                    ]
                }
            ]
            if (widgetsfinallist && widgetsfinallist.length > 0) {
                for (let i in widgetsfinallist) {
                    if (widgetsfinallist[i].w_name == 'Domain Scorecard') {
                        getDomainScoreCard({
                            payload: payload,
                            caller: login.form_url
                        })
                    }
                }
            }
        }
    }, [isAuth, entityid, entityData, widgetsfinallist])

    const Savewidget = () => {
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "SaveWidgetData",
                    {
                        "widget_info": [
                            {
                                "w_id": 5,
                                "width": "650px",
                                "height": "250px",
                                "pin_status": true
                            },
                            {
                                "w_id": 12,
                                "width": "600px",
                                "height": "258px",
                                "pin_status": true
                            },
                            {
                                "w_id": 4,
                                "width": "675px",
                                "height": "258px",
                                "pin_status": true
                            },
                            {
                                "w_id": 10,
                                "width": "675px",
                                "height": "250px",
                                "pin_status": true
                            },
                            {
                                "w_id": 1,
                                "width": "499.5px",
                                "height": "300px",
                                "pin_status": true
                            }
                        ]
                    }
                ]
            }
        ]
        console.log(paramid, "paramid456789");
        savewidgetdata({
            payload: payload,
            paramid: paramid

        })
    }


    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCalendarView",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                            "country_domains": countryDomain
                        }
                    ]
                }
            ]
            if (widgetsfinallist && widgetsfinallist.length > 0) {
                for (let i in widgetsfinallist) {
                    if (widgetsfinallist[i].w_name == 'Calendar View') {
                        getCalenderdata({
                            payload: payload,
                            caller: login.form_url
                        })
                    }
                }
            }
        }
    }, [isAuth,entityid, entityData, widgetsfinallist])

    // useEffect(() => {
    //     if (isAuth) {
    //         const payload = [
    //             authtoken,
    //             {
    //                 "session_token": authtoken,
    //                 "request": [
    //                     "GetCalendarView",
    //                     {
    //                         "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
    //                         "country_domains": countryDomain
    //                     }
    //                 ]
    //             }
    //         ]
    //         if (widgetsfinallist && widgetsfinallist.length > 0) {
    //             for (let i in widgetsfinallist) {
    //                 if (widgetsfinallist[i].w_name == 'Compfie Law') {
    //                         getCompfielaw({
    //                             payload: payload,
    //                             caller: login.form_url
    //                         })
    //                 }
    //             }
    //         }
    //     }
    // }, [isAuth,entityid, widgetsfinallist])


    const compfielawcard = () => {
        return (
            <>
                <div className='row'>
                    <div className='col-md-4' onClick={() => { setresizestate(!resizestate) }} title={resizestate == false ? "Minimize" : 'Maximize'}>{resizestate == false ? <MinusOutlined /> : <ExpandOutlined />} </div>
                    <div className='col-md-4' onClick={() => { alert("Saved Succesfully") }} title='Pinned'><PushpinOutlined /> </div>
                    <div className='col-md-4' onClick={() => { setcompfielaw(false) }} title='Close'><CloseOutlined /> </div>
                </div>
            </>
        )
    }

    const compliancestatuscard = () => {
        return (
            <>
                <div className='row'>
                    <div className='col-md-4' onClick={() => { setresizecompstatus(!resizecompstatus) }} title={resizecompstatus == false ? "Minimize" : 'Maximize'}> {resizecompstatus == false ? <MinusOutlined /> : <ExpandOutlined />}</div>
                    {/* <div className='col-md-4' title='Pinned' onClick={() => {
                        const payload = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "SaveWidgetData",
                                    {
                                        "widget_info": [
                                            {
                                                "w_id": 5,
                                                "width": "650px",
                                                "height": "250px",
                                                "pin_status": true
                                            },
                                            {
                                                "w_id": 12,
                                                "width": "600px",
                                                "height": "258px",
                                                "pin_status": true
                                            },
                                            {
                                                "w_id": 4,
                                                "width": "675px",
                                                "height": "258px",
                                                "pin_status": true
                                            },
                                            {
                                                "w_id": 10,
                                                "width": "675px",
                                                "height": "250px",
                                                "pin_status": true
                                            },
                                            {
                                                "w_id": 1,
                                                "width": "499.5px",
                                                "height": "300px",
                                                "pin_status": true
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                        console.log(paramid, "paramid456789");
                        savewidgetdata({
                            payload: payload,
                            paramid: '/dashboard'

                        })
                    }}
                    ><PushpinOutlined /> </div> */}
                    <div className='col-md-4' onClick={Savewidget} title='Pinned'><PushpinOutlined /> </div>
                    <div className='col-md-4' onClick={() => { setcompliancestatus(false) }} title='Close'><CloseOutlined /> </div>
                </div>
            </>
        )
    }
    const compliancetrendcard = () => {
        return (
            <>
                <div className='row'>
                    <div className='col-md-4' onClick={() => { setresizecomptrend(!resizecomptrend) }} title={resizecomptrend == false ? "Minimize" : 'Maximize'}> {resizecomptrend == false ? <MinusOutlined /> : <ExpandOutlined />} </div>
                    <div className='col-md-4' onClick={Savewidget} title='Pinned'><PushpinOutlined /> </div>
                    <div className='col-md-4' onClick={() => { setcompliancetrend(false) }} title='Close'><CloseOutlined /> </div>
                </div>
            </>
        )
    }
    const comparativetrendcard = () => {
        return (
            <>
                <div className='row'>
                    <div className='col-md-4' onClick={() => { setresizecomparative(!resizecomparative) }} title={resizecomparative == false ? "Minimize" : 'Maximize'}>{resizecomparative == false ? <MinusOutlined /> : <ExpandOutlined />} </div>
                    <div className='col-md-4' onClick={Savewidget} title='Pinned'><PushpinOutlined /> </div>
                    <div className='col-md-4' onClick={() => { setcomparativetrend(false) }} title='Close'><CloseOutlined /> </div>
                </div>
            </>
        )
    }
    const riskchartcard = () => {
        return (
            <>
                <div className='row'>
                    <div className='col-md-4' onClick={() => { setresizerisk(!resizerisk) }} title={resizerisk == false ? "Minimize" : 'Maximize'}>{resizerisk == false ? <MinusOutlined /> : <ExpandOutlined />} </div>
                    <div className='col-md-4' onClick={Savewidget} title='Pinned'><PushpinOutlined /> </div>
                    <div className='col-md-4' onClick={() => { setriskchart(false) }} title='Close'><CloseOutlined /> </div>
                </div>
            </>
        )
    }
    const overduecard = () => {
        return (
            <>
                <div className='row'>
                    <div className='col-md-4' onClick={() => { setresizeoverdue(!resizeoverdue) }} title={resizeoverdue == false ? "Minimize" : 'Maximize'}>{resizeoverdue == false ? <MinusOutlined /> : <ExpandOutlined />}</div>
                    <div className='col-md-4' onClick={Savewidget} title='Pinned'><PushpinOutlined /> </div>
                    <div className='col-md-4' onClick={() => { setoverdue(false) }} title='Close'><CloseOutlined /> </div>
                </div>
            </>
        )
    }
    const taskdistributioncard = () => {
        return (
            <>
                <div className='row'>
                    <div className='col-md-4' onClick={() => { setresizetdistribution(!resizetdistribution) }} title={resizetdistribution == false ? "Minimize" : 'Maximize'}>{resizetdistribution == false ? <MinusOutlined /> : <ExpandOutlined />} </div>
                    <div className='col-md-4' onClick={Savewidget} title='Pinned'><PushpinOutlined /> </div>
                    <div className='col-md-4' onClick={() => { settaskdistribution(false) }} title='Close'><CloseOutlined /> </div>
                </div>
            </>
        )
    }
    const domiancard = () => {
        return (
            <>
                <div className='row'>
                    <div className='col-md-4' onClick={() => { setresizedomain(!resizedomain) }} title={resizedomain == false ? "Minimize" : 'Maximize'}>{resizedomain == false ? <MinusOutlined /> : <ExpandOutlined />} </div>
                    <div className='col-md-4' onClick={Savewidget} title='Pinned'><PushpinOutlined /> </div>
                    <div className='col-md-4' onClick={() => { setdomainscore(false) }} title='Close'><CloseOutlined /> </div>
                </div>
            </>
        )
    }
    const assigneecard = () => {
        return (
            <>
                <div className='row'>
                    <div className='col-md-4' onClick={() => { setresizeassignee(!resizeassignee) }} title={resizeassignee == false ? "Minimize" : 'Maximize'}>{resizeassignee == false ? <MinusOutlined /> : <ExpandOutlined />} </div>
                    <div className='col-md-4' onClick={Savewidget} title='Pinned'><PushpinOutlined /> </div>
                    <div className='col-md-4' onClick={() => { setassigneescore(false) }} title='Close'><CloseOutlined /> </div>

                </div>
            </>
        )
    }
    const calendercard = () => {
        return (
            <>
                <div className='row'>
                    <div className='col-md-4' onClick={() => { setresizecalender(!resizecalender) }} title={resizecalender == false ? "Minimize" : 'Maximize'}>{resizecalender == false ? <MinusOutlined /> : <ExpandOutlined />} </div>
                    <div className='col-md-4' onClick={Savewidget} title='Pinned'><PushpinOutlined /> </div>
                    <div className='col-md-4' onClick={() => { setcalenderview(false) }} title='Close'><CloseOutlined /> </div>
                </div>
            </>
        )
    }
    const criticalitycard = () => {
        return (
            <>
                <div className='row'>
                    <div className='col-md-4' onClick={() => { setresizecriticality(!resizecriticality) }} title={resizecriticality == false ? "Minimize" : 'Maximize'}>{resizecriticality == false ? <MinusOutlined /> : <ExpandOutlined />} </div>
                    <div className='col-md-4' onClick={Savewidget} title='Pinned'><PushpinOutlined /> </div>
                    <div className='col-md-4' onClick={() => { setcriticality(false) }} title='Close'><CloseOutlined /> </div>
                </div>
            </>
        )
    }



    useEffect(() => {
        am4core.useTheme(am4themes_animated);
        // Themes end
        // Create chart instance
        var chart = am4core.create("compliancestatusdiv", am4charts.XYChart3D);
        // Add data
        chart.data = [{
            "type": 'India',
            "not_complied_count": widgetComplianceStatus && widgetComplianceStatus.widget_data && widgetComplianceStatus.widget_data[3].data[0].y,
            "complied_count": widgetComplianceStatus && widgetComplianceStatus.widget_data && widgetComplianceStatus.widget_data[0].data[0].y,
            "delayed_compliance_count": widgetComplianceStatus && widgetComplianceStatus.widget_data && widgetComplianceStatus.widget_data[1].data[0].y,
            "inprogress_compliance_count": widgetComplianceStatus && widgetComplianceStatus.widget_data && widgetComplianceStatus.widget_data[2].data[0].y,
        }
        ];
        chart.colors.list = [
            am4core.color("#0de580"),
            am4core.color("#3c78d8"),
            am4core.color("#fe4365"),
            am4core.color("#f9c80e"),



        ];
        chart.legend = new am4charts.Legend();
        chart.legend.position = "bottom";
        chart.legend.useDefaultMarker = true;
        chart.height = 230
        chart.width = 500
        chart.legend.fontSize = 10
        // var marker = chart.legend.markers.template.children.getIndex(0);
        // marker.cornerRadius(90, 90, 90, 90);
        // marker.strokeWidth = 0.5;
        let markerTemplate = chart.legend.markers.template;
        markerTemplate.width = 10;
        markerTemplate.height = 10;
        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarY = new am4core.Scrollbar();
        chart.scrollbarY.parent = chart.rightAxesContainer;
        var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());

        categoryAxis.dataFields.category = "type";
        var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.cellStartLocation = 0.2;
        valueAxis.renderer.cellEndLocation = 0.8;
        valueAxis.calculateTotals = true;
        valueAxis.title.text = "Total Compliances(2023)";
        function createSeries(field, name, id) {
            var series = chart.series.push(new am4charts.ColumnSeries3D());
            series.dataFields.valueX = field;
            series.dataFields.categoryY = "type";
            series.dataFields.customValue = id
            series.columns.template.maxWidth = 10
            series.columns.template.maxHeight = 30
            // series.dataFields.valueXShow = "total";
            series.stacked = true;
            series.name = name;
            // series.clustered = false;
            var labelBullet = series.bullets.push(new am4charts.LabelBullet());
            labelBullet.label.text = "{valueX}";
            labelBullet.locationX = 0.2;
            // series.columns.template.height = am4core.percent(70);
            // series.columns.template.column3D.stroke = am4core.color("#fff");
            // series.columns.template.column3D.strokeOpacity = 0.2;
            series.calculatePercent = true;
            series.calculateTotals = true;
            series.columns.template.tooltipText = "{name}: {valueX.totalPercent.formatNumber('#.00')}[/]%  ({valueX} outof {valueX.total}[/])";


        }

        createSeries("complied_count", "Complied", "typeId");
        createSeries("delayed_compliance_count", "Delayed Compliance", "typeId");
        createSeries("not_complied_count", "Not Complied", "typeId");
        createSeries("inprogress_compliance_count", "Inprogress", "typeId");
    });

    useEffect(() => {
        am4core.useTheme(am4themes_animated);
        // Themes end

        // Create chart instance

        var chart = am4core.create("compliancetrenddiv", am4charts.XYChart3D);

        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
        // Add data
        chart.data = [{
            'year': '2000',
            'complied_count': '44',
            'delayed_compliance_count': '85',
            'not_complied_count': '29',
        }, {
            'year': '2000',
            'complied_count': '44',
            'delayed_compliance_count': '85',
            'not_complied_count': '29',
        }, {
            'year': '2000',
            'complied_count': '44',
            'delayed_compliance_count': '85',
            'not_complied_count': '29',
        }]

        chart.colors.list = [
            am4core.color("#0cce73"),
            am4core.color("#366cc2"),
            am4core.color("#e53c5b"),
            // am4core.color("#fe4365"),
        ];

        // chart.colors.step = 2;
        // chart.padding(30, 30, 10, 30);
        chart.legend = new am4charts.Legend();

        chart.height = 300
        chart.width = 450
        chart.legend.useDefaultMarker = true;
        chart.legend.valueLabels.template.disabled = true;
        chart.legend.fontSize = 10

        // var marker = chart.legend.markers.template.children.getIndex(0);
        // marker.cornerRadius(10, 10, 10, 10);
        // marker.strokeWidth = 1;
        // marker.strokeOpacity = 0.5;
        // marker.stroke = am4core.color("#ccc");
        var markerTemplate = chart.legend.markers.template;
        markerTemplate.width = 10;
        markerTemplate.height = 10;
        // chart.cursor = new am4charts.XYCursor();
        // chart.legend = new am4charts.Legend();
        // chart.legend.useDefaultMarker = true;
        // var marker = chart.legend.markers.template.children.getIndex(0);
        // marker.cornerRadius(12, 12, 12, 12);
        // marker.strokeWidth = 2;
        // marker.strokeOpacity = 1;
        // marker.stroke = am4core.color("#ccc");


        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "year";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 1
        categoryAxis.renderer.cellStartLocation = 0.2;
        categoryAxis.renderer.cellEndLocation = 0.8;
        categoryAxis.title.text = "Year";

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.max = 100;
        valueAxis.strictMinMax = true;
        valueAxis.calculateTotals = true;
        valueAxis.renderer.minWidth = 40;
        valueAxis.renderer.minGridDistance = 40
        valueAxis.title.text = "Total Compliance(%)";

        var series1 = chart.series.push(new am4charts.ColumnSeries3D());
        series1.columns.template.width = am4core.percent(50);
        // series1.columns.template.tooltipText =
        //     "{name}: {valueY.totalPercent.formatNumber('#.00')}% : ";
        series1.columns.template.tooltipText =
            `{name}: {valueY.totalPercent.formatNumber('#.00')}% : ([bold]{compliedCount}[\] out of [bold]{total}[/])`;
        series1.name = "Complied";
        series1.dataFields.customValue = "compliedCount";
        series1.dataFields.categoryX = "year";
        series1.dataFields.valueY = "complied_count";
        series1.dataFields.valueYShow = "totalPercent";
        series1.dataItems.template.locations.categoryX = 0.5;
        series1.stacked = true;
        series1.tooltip.pointerOrientation = "vertical";

        var bullet1 = series1.bullets.push(new am4charts.LabelBullet());
        bullet1.interactionsEnabled = false;
        bullet1.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
        bullet1.label.fill = am4core.color("#ffffff");
        bullet1.locationY = 0.5;

        var series2 = chart.series.push(new am4charts.ColumnSeries3D());
        series2.columns.template.width = am4core.percent(50);
        series2.columns.template.tooltipText =
            "{name}: {valueY.totalPercent.formatNumber('#.00')}% : ([bold]{delayedCount}[/] out of [bold]{total}[/][/])";
        series2.name = "Delayed Compliance";
        series2.dataFields.customValue = "delayedCount";
        series2.dataFields.categoryX = "year";
        series2.dataFields.valueY = "delayed_compliance_count";
        series2.dataFields.valueYShow = "totalPercent";
        series2.dataItems.template.locations.categoryX = 0.5;
        series2.stacked = true;
        series2.tooltip.pointerOrientation = "vertical";

        var bullet2 = series2.bullets.push(new am4charts.LabelBullet());
        bullet2.interactionsEnabled = false;
        bullet2.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
        bullet2.locationY = 0.5;
        bullet2.label.fill = am4core.color("#ffffff");

        var series3 = chart.series.push(new am4charts.ColumnSeries3D());
        series3.columns.template.width = am4core.percent(50);
        series3.columns.template.tooltipText =
            "{name}: {valueY.totalPercent.formatNumber('#.00')}% : ([bold]{notCompliedCount}[/] out of [bold]{total}[/][/])";
        series3.name = "Not Complied";
        series3.dataFields.customValue = "notCompliedCount";
        series3.dataFields.categoryX = "year";
        series3.dataFields.valueY = "not_complied_count";
        series3.dataFields.valueYShow = "totalPercent";
        series3.dataItems.template.locations.categoryX = 0.5;
        series3.stacked = true;
        series3.tooltip.pointerOrientation = "vertical";

        var bullet3 = series3.bullets.push(new am4charts.LabelBullet());
        bullet3.interactionsEnabled = false;
        bullet3.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
        bullet3.locationY = 0.5;
        bullet3.label.fill = am4core.color("#ffffff");
    });

    useEffect(() => {
        am4core.useTheme(am4themes_animated);
        var chart = am4core.create("comparativetrenddiv", am4charts.XYChart3D);
        // Add data
        chart.data = [{
            "month": "jan",
            "value": 500,
            "total": 15220,
            "valuecount": 20
        },
        {
            "month": "feb",
            "value": 500,
            "total": 15220,
            "valuecount": 20
        }, {
            "month": "mar",
            "value": 500,
            "total": 15220,
            "valuecount": 20
        }, {
            "month": "april",
            "value": 500,
            "total": 15220,
            "valuecount": 20
        }]

        // Create axes
        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.minGridDistance = 1
        dateAxis.title.text = "Year";
        // Create value axis
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.calculateTotals = true;
        // valueAxis.min = 0;
        // valueAxis.max = 100;
        // valueAxis.renderer.minGridDistance = 10;
        valueAxis.min = 0;
        valueAxis.max = 100;
        valueAxis.renderer.minGridDistance = 10;
        valueAxis.renderer.cellStartLocation = 0.2;
        valueAxis.renderer.cellEndLocation = 0.6;
        valueAxis.title.text = "Compliance(%)";
        // Create series
        var lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.valueY = "value";
        lineSeries.dataFields.dateX = "month";
        // lineSeries.name = ((fieldType == "LegalEntity" && go == true) ? filterDataNames.legalentity : (
        //     (fieldType == "Division" && go == true) ? filterDataNames.division : (
        //         (fieldType == "Category" && go == true) ? filterDataNames.category : (
        //             (fieldType == "Unit" && go == true) ? filterDataNames.unit : commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name
        //         ))
        // ))
        lineSeries.dataFields.value = "totalPercent";
        lineSeries.calculatePercent = true;
        lineSeries.calculateTotals = true;
        lineSeries.tooltipText = "{valueX}"
        lineSeries.strokeWidth = 2;
        lineSeries.dataFields.valueXShow = "totalPercent";
        // Add simple bullet
        var bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
        bullet.circle.stroke = am4core.color("#fff");
        bullet.circle.strokeWidth = 2;
        bullet.tooltipText = "{name}: {valueY.formatNumber('#.00')}[/]% : ({valuecount} out of {total}[/]}";
        chart.legend = new am4charts.Legend();

        var title = chart.titles.create();
        // title.text = timeZoneData && timeZoneData.length > 0 ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
        title.disabled = false;
        title.align = 'left'
        title.marginTop = '2%'
        title.marginLeft = '2%'
        title.marginBottom = '2%'

        // var title1 = chart.titles.create();
        // title1.text = 'Delayed Compliance'
        // title1.disabled = false;
        // title1.align = 'center'
        // title1.marginTop = '2%'
        // title1.marginLeft = '2%'
        // title1.fontWeight = 'bold'

        var title1 = chart.titles.create();
        // title1.text =
        // ((fieldType == "LegalEntity" && go == true && type === '') ? `Delayed Compliance of Legal Entity [bold]${filterDataNames.legalentity}[/] (${year})` : (
        //     (fieldType == "Division" && go == true && type === '') ? `Delayed Compliance of Division [bold]${filterDataNames.division}[/] (${year})` : (
        //         (fieldType == "Category" && go == true && type === '') ? `Delayed Compliance of Category [bold]${filterDataNames.category}[/] (${year})` : (
        //             (fieldType == "BusinessGroup" && go == true && type === '') ? `Delayed Compliance of Business Group [bold]${filterDataNames.business}[/] (${year})` : (
        //                 (fieldType == "Unit" && go == true && type === '') ? `Delayed Compliance Unit [bold]${filterDataNames.unit}[/] (${year})` : `Delayed Compliance (${year})`
        //             )))
        // ))
        title1.disabled = false;
        title1.align = 'center'
        title1.marginTop = '5%'
        title1.marginLeft = '2%'
        // title1.fontWeight = 'bold'

        // <b><p>Country-Domain</p></b>
        // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

        var label = chart.createChild(am4core.Label);
        // label.text = "Country-Domain";
        label.fontSize = 14;
        label.align = "left";
        label.marginLeft = '2%';
        label.marginBottom = '2%'
        label.fontWeight = 'bold'

        var label1 = chart.createChild(am4core.Label);
        // label1.text = `[bold] "India"`
        label1.fontSize = 14;
        label1.align = "left";
        label1.marginLeft = '2%';
        label1.marginBottom = '5%'


    });
    useEffect(() => {
        am4core.useTheme(am4themes_animated);
        // Themes end

        // Create chart instance
        var chart = am4core.create("overduediv", am4charts.PieChart3D);
        var total = widgetoverdue && widgetoverdue.widget_data && widgetoverdue.widget_data[0].y + widgetoverdue && widgetoverdue.widget_data && widgetoverdue.widget_data[1].y + widgetoverdue && widgetoverdue.widget_data && widgetoverdue.widget_data[2].y + widgetoverdue && widgetoverdue.widget_data && widgetoverdue.widget_data[3].y
        // var total = 1000;
        // Add data

        chart.data = [{
            "country": widgetoverdue && widgetoverdue.widget_data && widgetoverdue.widget_data[0].name,
            "litres": widgetoverdue && widgetoverdue.widget_data && widgetoverdue.widget_data[0].y,
            "color": am4core.color("#f2746b")
        }, {
            "country": widgetoverdue && widgetoverdue.widget_data && widgetoverdue.widget_data[1].name,
            "litres": widgetoverdue && widgetoverdue.widget_data && widgetoverdue.widget_data[1].y,
            "color": am4core.color("#f2746b")
        }, {
            "country": widgetoverdue && widgetoverdue.widget_data && widgetoverdue.widget_data[2].name,
            "litres": widgetoverdue && widgetoverdue.widget_data && widgetoverdue.widget_data[2].y,
            "color": am4core.color("#ff6054")
        }, {
            "country": widgetoverdue && widgetoverdue.widget_data && widgetoverdue.widget_data[3].name,
            "litres": widgetoverdue && widgetoverdue.widget_data && widgetoverdue.widget_data[3].y,
            "color": am4core.color("#fe4365")
        }
        ];

        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries3D());
        pieSeries.dataFields.value = "litres";
        pieSeries.dataFields.category = "country";
        pieSeries.slices.template.propertyFields.fill = "color";
        chart.current = chart;
        chart.height = 180
        chart.width = 450
        chart.paddingTop = '12px'
        chart.legend = new am4charts.Legend();
        chart.innerRadius = am4core.percent(40);
        pieSeries.slices.template.tooltipText = `{category}: [bold]{value}[/] out of [bold]${total} `;


        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;

        chart.legend = new am4charts.Legend();
        chart.legend.useDefaultMarker = true;
        chart.legend.valueLabels.template.disabled = true;
        chart.legend.fontSize = 10

        // var marker = chart.legend.markers.template.children.getIndex(0);
        // marker.cornerRadius(12, 12, 12, 12);
        // marker.strokeWidth = 2;
        // marker.strokeOpacity = 1;
        // marker.stroke = am4core.color("#ccc");
        var markerTemplate = chart.legend.markers.template;
        markerTemplate.width = 10;
        markerTemplate.height = 10;

        chart.hiddenState.properties.radius = am4core.percent(0);


    });


    useEffect(() => {
        am4core.useTheme(am4themes_animated);
        var chart = am4core.create("riskchartdiv2", am4charts.PieChart3D);
        var total = (widgetRisk && widgetRisk.widget_data && widgetRisk.widget_data[0].y + widgetRisk && widgetRisk.widget_data && widgetRisk.widget_data[1].y)
        chart.data = [
            //     {
            //     "country": widgetRisk && widgetRisk.widget_data && widgetRisk.widget_data[0].name,
            //     "litres": widgetRisk && widgetRisk.widget_data && widgetRisk.widget_data[0].y,
            //     "color": am4core.color("#3c78d8")
            // },
            {
                "country": widgetRisk && widgetRisk.widget_data && widgetRisk.widget_data[1].name,
                "litres": widgetRisk && widgetRisk.widget_data && widgetRisk.widget_data[1].y,
                "color": am4core.color("#fe4365")
            }
        ];


        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries3D());
        pieSeries.dataFields.value = "litres";
        pieSeries.dataFields.category = "country";
        pieSeries.slices.template.propertyFields.fill = "color";
        chart.current = chart;
        chart.paddingTop = '10px'
        chart.height = 200
        chart.width = 350
        chart.legend = new am4charts.Legend();
        chart.innerRadius = am4core.percent(40);
        pieSeries.slices.template.tooltipText = `{category}: [bold]{value}[/] out of ${total} `;
        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;
        var title2 = chart.createChild(am4core.Label)
        title2.text = "Exposed Risk"
        // title2.disabled = false;
        // // title2.align = 'left'
        // title2.paddingTop = '22%'
        // // title2.paddingLeft = '19%'
        title2.fontWeight = 'bold'
        title2.fontSize = '14'
        chart.legend = new am4charts.Legend();
        chart.legend.useDefaultMarker = true;
        chart.layout = 'horizontal'
        chart.legend.valueLabels.template.disabled = true;
        chart.legend.fontSize = 8
        chart.width = am4core.percent(70);

        // var marker = chart.legend.markers.template.children.getIndex(0);
        // marker.cornerRadius(12, 12, 12, 12);
        // marker.strokeWidth = 2;
        // marker.strokeOpacity = 1;
        // marker.stroke = am4core.color("#ccc");
        var markerTemplate = chart.legend.markers.template;
        markerTemplate.width = 10;
        markerTemplate.height = 10;


        chart.hiddenState.properties.radius = am4core.percent(0);
    });

    useEffect(() => {
        am4core.useTheme(am4themes_animated);
        // Themes end

        // Create chart instance
        var chart = am4core.create("riskchartdiv1", am4charts.PieChart3D);
        var total = (widgetRisk && widgetRisk.widget_data && widgetRisk.widget_data[2].y + widgetRisk && widgetRisk.widget_data && widgetRisk.widget_data[3].y + widgetRisk && widgetRisk.widget_data && widgetRisk.widget_data[4].y)

        // Add data

        chart.data = [,
            {
                "country": widgetRisk && widgetRisk.widget_data && widgetRisk.widget_data[2].name,
                "litres": widgetRisk && widgetRisk.widget_data && widgetRisk.widget_data[2].y,
                "color": am4core.color("#808080")
            },
            {
                "country": widgetRisk && widgetRisk.widget_data && widgetRisk.widget_data[3].name,
                "litres": widgetRisk && widgetRisk.widget_data && widgetRisk.widget_data[3].y,
                "color": am4core.color("#dd070c")
            }
            ,
            {
                "country": widgetRisk && widgetRisk.widget_data && widgetRisk.widget_data[4].name,
                "litres": widgetRisk && widgetRisk.widget_data && widgetRisk.widget_data[4].y,
                "color": am4core.color("#ff9c80")
            }
        ];
        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries3D());
        pieSeries.dataFields.value = "litres";
        pieSeries.dataFields.category = "country";
        pieSeries.slices.template.propertyFields.fill = "color";
        chart.current = chart;
        chart.paddingTop = '10px'
        chart.height = 200
        chart.width = 350
        chart.legend = new am4charts.Legend();
        chart.innerRadius = am4core.percent(40);
        chart.width = am4core.percent(70);
        pieSeries.slices.template.tooltipText = `{category}: [bold]{value}[/] out of ${total} `;
        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;
        var title1 = chart.createChild(am4core.Label)
        title1.text = "Hidden Risk"
        // title1.disabled = false;
        // // title1.align = 'left'
        // title1.paddingTop = '22%'
        // // title1.paddingLeft = '19%'
        title1.fontWeight = 'bold'
        title1.fontSize = '14'
        chart.legend = new am4charts.Legend();
        chart.legend.useDefaultMarker = true;
        chart.layout = 'horizontal'
        chart.legend.valueLabels.template.disabled = true;
        chart.legend.fontSize = 8
        // var marker = chart.legend.markers.template.children.getIndex(0);
        // marker.cornerRadius(12, 12, 12, 12);
        // marker.strokeWidth = 2;
        // marker.strokeOpacity = 1;
        // marker.stroke = am4core.color("#ccc");
        var markerTemplate = chart.legend.markers.template;
        markerTemplate.width = 10;
        markerTemplate.height = 10;
        chart.hiddenState.properties.radius = am4core.percent(0);

    });

    useEffect(() => {
        am4core.useTheme(am4themes_animated);
        // Themes end

        // Create chart instance
        var chart = am4core.create("taskdistributiondiv", am4charts.PieChart3D);
        var total = WidgetTaskdistribution && WidgetTaskdistribution.widget_data && WidgetTaskdistribution.widget_data[0].y + WidgetTaskdistribution && WidgetTaskdistribution.widget_data && WidgetTaskdistribution.widget_data[1].y + WidgetTaskdistribution && WidgetTaskdistribution.widget_data && WidgetTaskdistribution.widget_data[2].y + WidgetTaskdistribution && WidgetTaskdistribution.widget_data && WidgetTaskdistribution.widget_data[3].y

        // Add data

        chart.data = [{
            "country": WidgetTaskdistribution && WidgetTaskdistribution.widget_data && WidgetTaskdistribution.widget_data[0].name,
            "litres": WidgetTaskdistribution && WidgetTaskdistribution.widget_data && WidgetTaskdistribution.widget_data[0].y,
            "color": am4core.color("#00a3a3")
        }, {
            "country": WidgetTaskdistribution && WidgetTaskdistribution.widget_data && WidgetTaskdistribution.widget_data[1].name,
            "litres": WidgetTaskdistribution && WidgetTaskdistribution.widget_data && WidgetTaskdistribution.widget_data[1].y,
            "color": am4core.color("#ff9c80")
        }, {
            "country": WidgetTaskdistribution && WidgetTaskdistribution.widget_data && WidgetTaskdistribution.widget_data[2].name,
            "litres": WidgetTaskdistribution && WidgetTaskdistribution.widget_data && WidgetTaskdistribution.widget_data[2].y,
            "color": am4core.color("#dd070c")
        }, {
            "country": WidgetTaskdistribution && WidgetTaskdistribution.widget_data && WidgetTaskdistribution.widget_data[3].name,
            "litres": WidgetTaskdistribution && WidgetTaskdistribution.widget_data && WidgetTaskdistribution.widget_data[3].y,
            "color": am4core.color("#808080")
        }
        ];


        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries3D());
        pieSeries.dataFields.value = "litres";
        pieSeries.dataFields.category = "country";
        pieSeries.slices.template.propertyFields.fill = "color";
        chart.current = chart;
        chart.height = 180
        chart.width = 400
        chart.paddingTop = '11px'
        chart.legend = new am4charts.Legend();
        chart.align = 'center'
        pieSeries.slices.template.tooltipText = `{category}: [bold]{value}[/] out of [bold]${total} `;


        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;

        chart.legend = new am4charts.Legend();
        chart.legend.useDefaultMarker = true;
        chart.legend.valueLabels.template.disabled = true;
        chart.legend.fontSize = 10
        chart.legend.align = 'right'
        var title1 = chart.createChild(am4core.Label)
        title1.text = "Total Task : 5878"
        title1.fontWeight = 'bold'
        title1.align = 'center'
        title1.marginBottom = '5px'

        // var marker = chart.legend.markers.template.children.getIndex(0);
        // marker.cornerRadius(12, 12, 12, 12);
        // marker.strokeWidth = 2;
        // marker.strokeOpacity = 1;
        // marker.stroke = am4core.color("#ccc");
        var markerTemplate = chart.legend.markers.template;
        markerTemplate.width = 10;
        markerTemplate.height = 10;


        chart.hiddenState.properties.radius = am4core.percent(0);


    });
    useEffect(() => {
        am4core.useTheme(am4themes_animated);
        // Themes end

        // Create chart instance
        var chart = am4core.create("criticalitydiv", am4charts.PieChart3D);
        // var total = WidgetCriticality && WidgetCriticality.widget_data && WidgetCriticality.widget_data[0].y + WidgetCriticality && WidgetCriticality.widget_data && WidgetCriticality.widget_data[1].y + WidgetCriticality && WidgetCriticality.widget_data && WidgetCriticality.widget_data[2].y + WidgetCriticality && WidgetCriticality.widget_data && WidgetCriticality.widget_data[3].y
        var total = 2550
        // Add data

        chart.data = [{
            "country": WidgetCriticality && WidgetCriticality.widget_data && WidgetCriticality.widget_data[0].name,
            "litres": WidgetCriticality && WidgetCriticality.widget_data && WidgetCriticality.widget_data[0].y,
            "color": am4core.color("#fe4365")
        }, {
            "country": WidgetCriticality && WidgetCriticality.widget_data && WidgetCriticality.widget_data[1].name,
            "litres": WidgetCriticality && WidgetCriticality.widget_data && WidgetCriticality.widget_data[1].y,
            "color": am4core.color("#ff6054")
        }, {
            "country": WidgetCriticality && WidgetCriticality.widget_data && WidgetCriticality.widget_data[2].name,
            "litres": WidgetCriticality && WidgetCriticality.widget_data && WidgetCriticality.widget_data[2].y,
            "color": am4core.color("#f2746b")
        }
        ];

        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries3D());
        pieSeries.dataFields.value = "litres";
        pieSeries.dataFields.category = "country";
        pieSeries.slices.template.propertyFields.fill = "color";
        chart.current = chart;
        chart.height = 180
        chart.width = 400
        chart.paddingTop = '10px'
        chart.legend = new am4charts.Legend();
        pieSeries.slices.template.tooltipText = `{category}: [bold]{value}[/] out of [bold]${total} `;
        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;
        chart.legend = new am4charts.Legend();
        chart.legend.useDefaultMarker = true;
        chart.layout = 'horizontal'
        chart.legend.valueLabels.template.disabled = true;
        chart.legend.fontSize = 10
        // var marker = chart.legend.markers.template.children.getIndex(0);
        // marker.cornerRadius(12, 12, 12, 12);
        // marker.strokeWidth = 2;
        // marker.strokeOpacity = 1;
        // marker.stroke = am4core.color("#ccc");
        var markerTemplate = chart.legend.markers.template;
        markerTemplate.width = 10;
        markerTemplate.height = 10;
        chart.hiddenState.properties.radius = am4core.percent(0);
    });
    useEffect(() => {
        if (WidgetCalenderData) {
            let temp = []
            if (WidgetCalenderData.widget_data && WidgetCalenderData.widget_data.length) {
                let arr = WidgetCalenderData.widget_data[0].CurrentMonth.split('-')
                arr.pop()
                // arr.toString("-")
                console.log(arr, 'arrrrr');
                for (let i in WidgetCalenderData.widget_data[0].data) {
                    if (WidgetCalenderData.widget_data[0].data[i].overdue > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${WidgetCalenderData.widget_data[0].data[i].date}`).toString()
                        console.log(date, 'date555555');
                        temp.push(
                            {
                                id: "over Due",
                                title: WidgetCalenderData.widget_data[0].data[i].overdue,
                                allDay: true,
                                start: date,
                                end: date,
                                color: '#f32d2b',
                                entity: '',
                                tooltip: `Over Due ${WidgetCalenderData.widget_data[0].data[i].overdue}`


                            },
                        )
                    }
                    if (WidgetCalenderData.widget_data[0].data[i].inprogress > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${WidgetCalenderData.widget_data[0].data[i].date}`).toString()
                        temp.push(
                            {
                                id: "Inprogress",
                                title: WidgetCalenderData.widget_data[0].data[i].inprogress,
                                allDay: true,
                                start: date,
                                end: date,
                                color: '#f0f468',
                                entity: "",
                                tooltip: `Inprogress ${WidgetCalenderData.widget_data[0].data[i].inprogress}`
                            },
                        )
                    }
                    if (WidgetCalenderData.widget_data[0].data[i].upcoming > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${WidgetCalenderData.widget_data[0].data[i].date}`).toString()
                        temp.push(
                            {
                                id: "Upcoming",
                                title: WidgetCalenderData.widget_data[0].data[i].upcoming,
                                allDay: true,
                                start: date,
                                end: date,
                                color: '#87ceeb',
                                entity: '',
                                tooltip: `Upcoming ${WidgetCalenderData.widget_data[0].data[i].upcoming}`
                            },
                        )
                    }
                    if (WidgetCalenderData.widget_data[0].data[i].duedate > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${WidgetCalenderData.widget_data[0].data[i].date}`).toString()
                        temp.push(
                            {
                                id: "Due Date",
                                title: WidgetCalenderData.widget_data[0].data[i].duedate,
                                allDay: true,
                                start: date,
                                end: date,
                                color: '#c69ee2',
                                entity: '',
                                tooltip: `Due Date ${WidgetCalenderData.widget_data[0].data[i].duedate}`
                            },
                        )
                    }
                    if (WidgetCalenderData.widget_data[0].data[i].taskrecall > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${WidgetCalenderData.widget_data[0].data[i].date}`).toString()
                        temp.push(
                            {
                                id: "Task Recall",
                                title: WidgetCalenderData.widget_data[0].data[i].taskrecall,
                                allDay: true,
                                start: date,
                                end: date,
                                color: '#da6d25',
                                entity: '',
                                tooltip: `Task Recall ${WidgetCalenderData.widget_data[0].data[i].taskrecall}`

                            },
                        )
                    }


                }
            }
            setEventData(temp)

        }

    }, [WidgetCalenderData])

    const columns1 = [
        {
            title: 'Role',
            // ellipsis: true,
            width: '70px',
            dataIndex: 'Role',
            key: 'Role',
        },
        {
            title: 'Assignee',
            // ellipsis: true,
            width: '30px',
            align: 'center',
            dataIndex: 'Assingee',
            key: 'Assingee',
        },
        {
            title: 'Concur',
            // ellipsis: true,
            width: '30px',
            align: 'center',
            dataIndex: 'Concur',
            key: 'Concur',
        },
        {
            title: 'Approve',
            width: '30px',
            // ellipsis: true,
            align: 'center',
            dataIndex: 'Approver',
            key: 'Approver',
        },

    ];
    const columns = [
        {
            title: 'Domain',
            // ellipsis: true,
            width: '70px',
            dataIndex: 'd_name',
            key: 'd_name',
        },
        {
            title: 'Assigned',
            // ellipsis: true,
            width: '30px',
            align: 'center',
            dataIndex: 'assigned',
            key: 'assigned',
        },
        {
            title: 'UnAssigned',
            // ellipsis: true,
            width: '30px',
            align: 'center',
            dataIndex: 'unassinged',
            key: 'unassinged',
        },
        {
            title: 'Not Opted',
            width: '30px',
            // ellipsis: true,
            align: 'center',
            dataIndex: 'notopted',
            key: 'notopted',
        },
        {
            title: 'Not Applicable',
            width: '30px',
            // ellipsis: true,
            align: 'center',
            dataIndex: 'not_applicable',
            key: 'not_applicable',
        },
        {
            title: 'Total',
            width: '30px',
            // ellipsis: true,
            align: 'center',
            dataIndex: 'Approver',
            key: 'Approver',
        },

    ];
    // const homescreen = () => {
    //     window.location.href = '/dashboard'
    // }
    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="page-titles pb-0 pt-2">
                    <div className="row">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                {/* <li className="breadcrumb-item">
                                    <Link className='pointer'><i className="ri-home-3-line fs-5"></i></Link>
                                </li> */}

                                {/* <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ color: '#1890ff' }}>Widgets</span>
                                </li> */}
                            </ol>
                        </nav>
                    </div>
                    <br></br>
                    {widgetinfoname.includes("Compliance Status") ?
                        <div className='col-md-12'>
                            {compliancestatus == true ?
                                <Card title='Compliance Status' extra={compliancestatuscard()} style={{ height: resizecompstatus === true ? '60px' : compstatussize[0].height == '0px' ? '400px' : compstatussize[0].height, width: compstatussize[0].width == '0px' ? '600px' : compstatussize[0].width }}
                                    headStyle={{ backgroundColor: "#73C4E9" }} >
                                    <div id="compliancestatusdiv" style={{ height: '500px', width: '550px', display: resizecompstatus === true ? 'none' : '' }}></div>
                                </Card> : ''}
                        </div> : ''}
                    <br></br>
                    {widgetinfoname.includes("Compliance Trend") ?
                        <div className='col-md-12' style={{ marginTop: '20px' }}>
                            {compliancetrend == true ?
                                <Card title='Compliance Trend' extra={compliancetrendcard()} style={{ height: resizecomptrend === true ? '60px' : comptrendsize[0].height == '0px' ? '450px' : comptrendsize[0].height, width: comptrendsize[0].width == '0px' ? '400px' : comptrendsize[0].width }}
                                    headStyle={{ backgroundColor: "#73C4E9" }}>
                                    {/* <div id="compliancetrenddiv" style={{ height: '400px', width: '400px', display: resizecomptrend === true ? 'none' : '' }}></div> */}
                                </Card> : ''}
                        </div> : ''}
                    <br></br>
                    {widgetinfoname.includes('Comparative Trend') ?
                        <div className='col-md-12' style={{ marginTop: '20px' }}>
                            {comparativetrend == true ?
                                <Card title='Comparative Trend' extra={comparativetrendcard()} style={{ height: resizecomparative === true ? '60px' : comparativetrendsize[0].height == '0px' ? '400px' : comparativetrendsize[0].height, width: comparativetrendsize[0].width == '0px' ? '550px' : comparativetrendsize[0].width }}
                                    headStyle={{ backgroundColor: "#73C4E9" }}>
                                    {/* <div id="comparativetrenddiv" style={{ height: '350px', width: '500px', display: resizecomparative === true ? 'none' : '' }}></div> */}
                                </Card> : ''}
                        </div> : ''}
                    <br></br>
                    {widgetinfoname.includes("Criticality") ?
                        <div className='col-md-12' style={{ marginTop: '20px' }}>
                            {criticality == true ?
                                <Card title='Criticality' extra={criticalitycard()} style={{ height: resizecriticality === true ? '60px' : criticalitysize[0].height == '0px' ? '350px' : criticalitysize[0].height, width: criticalitysize[0].width == '0px' ? '550px' : criticalitysize[0].width }}
                                    headStyle={{ backgroundColor: "#73C4E9" }}>
                                    <div id="criticalitydiv" style={{ height: '200px', width: '400px', display: resizecriticality === true ? 'none' : '' }}></div>
                                </Card> : ''}
                        </div> : ''}
                    <br></br>
                    {widgetinfoname.includes("Risk Chart") ?
                        <div className='col-md-12' style={{ marginTop: '20px' }}>
                            {riskchart == true ?
                                <Card title='Risk Chart' extra={riskchartcard()} style={{ height: resizerisk === true ? '60px' : riskchartsize[0].height == '0px' ? '550px' : riskchartsize[0].height, width: riskchartsize[0].width == '0px' ? '500px' : riskchartsize[0].width }}
                                    headStyle={{ backgroundColor: "#73C4E9" }}>
                                    <div id="riskchartdiv1" style={{ height: '200px', width: '400px', display: resizerisk === true ? 'none' : '' }}></div>
                                    <div id="riskchartdiv2" style={{ height: '200px', width: '400px', display: resizerisk === true ? 'none' : '' }}></div>
                                </Card> : ''}
                        </div> : ''}
                    <br></br>
                    {widgetinfoname.includes("Task Distribution") ?
                        <div className='col-md-12' style={{ marginTop: '70px' }}>
                            {taskdistribution == true ?
                                <Card title='Task Distribution' extra={taskdistributioncard()} style={{ height: resizetdistribution === true ? '60px' : taskdistributionsize[0].height == '0px' ? '400px' : taskdistributionsize[0].height, width: taskdistributionsize[0].width == '0px' ? '500px' : taskdistributionsize[0].width }}
                                    headStyle={{ backgroundColor: "#73C4E9" }}>
                                    <div id="taskdistributiondiv" style={{ height: '200px', width: '450px', display: resizetdistribution === true ? 'none' : '' }}></div>
                                </Card> : ''}
                        </div> : ''}
                    <br></br>
                    {widgetinfoname.includes("Over Due Chart") ?
                        <div className='col-md-12' style={{ marginTop: '20px' }}>
                            {overdue == true ?
                                <Card title='Over Due Chart' extra={overduecard()} style={{ height: resizeoverdue === true ? '60px' : overduesize[0].height == '0px' ? '300px' : overduesize[0].height, width: overduesize[0].width == '0px' ? '500px' : overduesize[0].width }}
                                    headStyle={{ backgroundColor: "#73C4E9" }}>
                                    <div id="overduediv" style={{ height: '220px', width: '450px', display: resizeoverdue === true ? 'none' : '' }}></div>
                                </Card> : ''}
                        </div> : ''}
                    <br></br>
                    {widgetinfoname.includes("Domain Scorecard") ?
                        <div className='col-md-12' style={{ marginTop: '20px' }}>
                            {domainscore == true ?
                                <Card title='Domain Scorecard' extra={domiancard()} style={{ width: '600px', height: resizedomain === true ? '60px' : '350px' }}
                                    headStyle={{ backgroundColor: "#73C4E9" }}>
                                    {resizedomain == false ?
                                        // <Table
                                        //     className='userprivclass'
                                        //     columns={columns}
                                        //     dataSource={filteredArray}
                                        //     bordered
                                        //     pagination={false} />
                                        <div className='ant-table-wrapper'>
                                            <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                <div className='ant-table-container'>
                                                    <div className='ant-table-content userprivclass1'>
                                                        <table className='table-fixed'>
                                                            <colgroup>
                                                                <col style={{ width: '10px' }}></col>
                                                            </colgroup>
                                                            <thead className='ant-table-thead'>
                                                                <tr className='tablehover'>
                                                                    <th className='ant-table-cell custom-width1'>Domain</th>
                                                                    <th className='ant-table-cell custom-width1'>Assigned</th>
                                                                    <th className='ant-table-cell custom-width1'><center>Un Assigned</center></th>
                                                                    <th className='ant-table-cell custom-width1'>Not Opted</th>
                                                                    <th className='ant-table-cell custom-width1'>Not Applicable</th>
                                                                    <th className='ant-table-cell custom-width1'>Total</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='ant-table-tbody'>
                                                                {filteredArray && filteredArray.length > 0 && filteredArray.map((item, i) => {
                                                                    return (<>
                                                                        <tr className='tablehover'>

                                                                            <td className='ant-table-cell'><center>{item.d_name}</center></td>
                                                                            <td className='ant-table-cell'><center>{item.assigned}</center></td>
                                                                            <td className='ant-table-cell'><center>{item.unassinged}</center></td>
                                                                            <td className='ant-table-cell'><center>{item.notopted}</center></td>
                                                                            <td className='ant-table-cell'><center>{item.not_applicable}</center></td>
                                                                            <td className='ant-table-cell'><center><b>{Number(item.assigned) + Number(item.unassinged) + Number(item.notopted) + Number(item.not_applicable)}</b></center></td>
                                                                        </tr>
                                                                        <tr className='tablehover'>
                                                                            <td className='ant-table-cell'><b><center>Total</center></b></td>
                                                                            <td className='ant-table-cell'><center>{item.assigned}</center></td>
                                                                            <td className='ant-table-cell'><center>{item.unassinged}</center></td>
                                                                            <td className='ant-table-cell'><center>{item.notopted}</center></td>
                                                                            <td className='ant-table-cell'><center>{item.not_applicable}</center></td>
                                                                            <td className='ant-table-cell'><center><b>{Number(item.assigned) + Number(item.unassinged) + Number(item.notopted) + Number(item.not_applicable)}</b></center></td>
                                                                        </tr>
                                                                    </>
                                                                    )
                                                                })}

                                                            </tbody>

                                                        </table>
                                                        <br />
                                                        <div>
                                                            <div
                                                                // className="btn btn-light-success rounded-pill px-4 text-success"
                                                                className='col-md-12'
                                                                style={{ marginLeft: '90%', marginBottom: '5%' }}
                                                            // onClick={submitrec}
                                                            >
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ''}
                                </Card> : ''}
                        </div> : ''}
                    <br></br>
                    {widgetinfoname.includes("Assignee Scorecard") ?
                        <div className='col-md-12' style={{ marginTop: '20px' }}>
                            {assigneescore == true ?
                                <Card title='Assignee Scorecard' extra={assigneecard()} style={{ width: '550px', height: resizeassignee === true ? '60px' : '450px' }}
                                    headStyle={{ backgroundColor: "#73C4E9" }}>
                                    {resizeassignee == false ?
                                        // <Table
                                        //     className='userprivclass'
                                        //     columns={columns1}
                                        //     dataSource={domainfilterdata}
                                        //     bordered
                                        //     pagination={false} /> 
                                        <div className='ant-table-wrapper'>
                                            <div className='ant-table ant-table-bordered'>
                                                <div className='ant-table-container'>
                                                    <div className='ant-table-content userprivclass1'>
                                                        <table>
                                                            <colgroup>
                                                                <col style={{ width: '10px' }}></col>
                                                            </colgroup>
                                                            <thead className='ant-table-thead'>
                                                                <tr className='tablehover'>
                                                                    <th className='ant-table-cell custom-width1'>Role</th>
                                                                    <th className='ant-table-cell custom-width1'>Assignee</th>
                                                                    <th className='ant-table-cell custom-width1'><center>Concur</center></th>
                                                                    <th className='ant-table-cell custom-width1'>Approver</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='ant-table-tbody'>
                                                                <tr className='tablehover'>
                                                                    <td className='ant-table-cell'><center>{domainfilterdata && domainfilterdata.length > 0 && domainfilterdata[0].Role}</center></td>
                                                                    <td className='ant-table-cell'><center>{domainfilterdata && domainfilterdata.length > 0 && domainfilterdata[0].Assingee}</center></td>
                                                                    <td className='ant-table-cell'><center>{domainfilterdata && domainfilterdata.length > 0 && domainfilterdata[0].Concur}</center></td>
                                                                    <td className='ant-table-cell'><center>{domainfilterdata && domainfilterdata.length > 0 && domainfilterdata[0].Approver}</center></td>
                                                                </tr>
                                                                <tr className='tablehover'>
                                                                    <td className='ant-table-cell'><center>{domainfilterdata && domainfilterdata.length > 0 && domainfilterdata[1].Role}</center></td>
                                                                    <td className='ant-table-cell'><center>{domainfilterdata && domainfilterdata.length > 0 && domainfilterdata[1].Assingee}</center></td>
                                                                    <td className='ant-table-cell'><center>{domainfilterdata && domainfilterdata.length > 0 && domainfilterdata[1].Concur}</center></td>
                                                                    <td className='ant-table-cell'><center>{domainfilterdata && domainfilterdata.length > 0 && domainfilterdata[1].Approver}</center></td>

                                                                </tr>
                                                                <tr className='tablehover'>
                                                                    <td className='ant-table-cell'><center>{domainfilterdata && domainfilterdata.length > 0 && domainfilterdata[2].Role}</center></td>
                                                                    <td className='ant-table-cell'><center>{domainfilterdata && domainfilterdata.length > 0 && domainfilterdata[2].Assingee}</center></td>
                                                                    <td className='ant-table-cell'><center>{domainfilterdata && domainfilterdata.length > 0 && domainfilterdata[2].Concur}</center></td>
                                                                    <td className='ant-table-cell'><center>{domainfilterdata && domainfilterdata.length > 0 && domainfilterdata[2].Approver}</center></td>

                                                                </tr>
                                                                <tr className='tablehover' >
                                                                    <td className='ant-table-cell'><center><b>Total</b></center></td>
                                                                    <td className='ant-table-cell'><center>{domainfilterdata && domainfilterdata.length > 0 && domainfilterdata[0].Assingee + domainfilterdata[1].Assingee + domainfilterdata[2].Assingee}</center></td>
                                                                    <td className='ant-table-cell'><center>{domainfilterdata && domainfilterdata.length > 0 && domainfilterdata[0].Concur + domainfilterdata[1].Concur + domainfilterdata[2].Concur}</center></td>
                                                                    <td className='ant-table-cell'><center><b>{domainfilterdata && domainfilterdata.length > 0 && domainfilterdata[0].Approver + domainfilterdata[1].Approver + domainfilterdata[2].Approver}</b></center></td>
                                                                </tr>
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ''}
                                </Card> : ''}
                        </div> : ''}
                    <br></br>
                    {widgetinfoname.includes("Compfie Law") ?
                        <div className='col-md-12' style={{ marginTop: '20px' }}>
                            {compfielaw == true ?
                                <Card title='Compfie Law' extra={compfielawcard()} style={{ width: '300px', height: resizestate === true ? '60px' : '300px' }}
                                    headStyle={{ backgroundColor: "#73C4E9" }}>
                                    {/* <img src="comfielaws.png" width="50%" height="inherit"></img> */}
                                    <img src="logo-icon.png" alt="homepage" class="dark-logo w-75 mt-4 " style={{ display: resizestate === true ? 'none' : '' }}></img>
                                </Card> : ''}
                        </div> : ''}
                    <br></br>
                    {widgetinfoname.includes("Calendar View") ?
                        <div className='col-md-12' style={{ marginTop: '20px' }}>
                            {calenderview == true ?
                                <Card title='Calendar View' extra={calendercard()} style={{ width: '600px', height: resizecalender === true ? '60px' : '650px' }}
                                    headStyle={{ backgroundColor: "#73C4E9" }}>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                {resizecalender == false ?

                                                    <div className="card">
                                                        <Calendar
                                                            style={{ height: '300pt' }}
                                                            tooltipAccessor={(e) => {
                                                                return e.tooltip
                                                            }}
                                                            // views={views}
                                                            // components={(e) => {
                                                            //     Event(e)
                                                            // }}
                                                            // components={{
                                                            //     toolbar: CustomToolbar
                                                            // }}
                                                            eventPropGetter={(event, start, end, isSelected) => {
                                                                console.log(event);
                                                                var backgroundColor = event.color;
                                                                var style = {
                                                                    backgroundColor: backgroundColor,
                                                                    borderRadius: '25px',
                                                                    float: 'left',
                                                                    fontSize: '10px !important',
                                                                    height: '30px',
                                                                    textAlign: 'center',
                                                                    lineHeight: '30px',
                                                                    verticalAlign: 'middle',
                                                                    marginLeft: '5px',
                                                                    width: '30px',
                                                                };
                                                                return {
                                                                    style: style
                                                                };
                                                            }}
                                                            // date={date}
                                                            events={eventData}
                                                            startAccessor="start"
                                                            endAccessor="end"
                                                            defaultDate={moment().toDate()}
                                                            localizer={localizer}
                                                            eventOrder="horizontal"
                                                            defaultView='month'
                                                        // onSelectEvent={onSelectEvent}
                                                        />
                                                        <div className="col-md-12 mt-4">
                                                            <div className="row" style={{ marginLeft: '10%' }}>
                                                                <div style={{ fontSize: '13px', fontWeight: 'bold' }}>
                                                                    <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                                        <div style={{ background: ' #da6d25', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                                        <div style={{ float: 'left' }}>Task Recall</div>
                                                                    </div>
                                                                    <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                                        <div style={{ background: '#c69ee2', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                                        <div style={{ float: 'left' }}>Due Date</div>
                                                                    </div>
                                                                    <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                                        <div style={{ background: '#f0f468', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                                        <div style={{ float: 'left' }}>Inprogress</div>
                                                                    </div>
                                                                    <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                                        <div style={{ background: '#f32d2b', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                                        <div style={{ float: 'left' }}>Over Due</div>
                                                                    </div>
                                                                    <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                                        <div style={{ background: '  #87ceeb', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                                        <div style={{ float: 'left' }}>Upcoming</div>
                                                                    </div>
                                                                    &nbsp; </div>
                                                            </div>
                                                        </div>
                                                    </div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </Card> : ''}
                        </div> : ''}
                </div>
            </div>
        </Fragment>
    )
}
export default connect(mapStateToProps, {
    getCommonwidgetData,
    getCommonwidgetData1,
    getDomainScoreCard,
    getwidget1Data,
    getwidget2Data,
    getUserScoreCard,
    getwidget3Data,
    getwidget4Data,
    getwidget5Data,
    getwidget6Data,
    getwidget7Data,
    getCalenderdata,
    getCompfielaw,
    savewidgetdata
})(Dashboard);