import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import { forgotPassword } from '../Store/Action/Forgotpassword';
import { useLocation, useHistory, Link } from 'react-router-dom';
const mapStateToProps = (state) => ({

})

const Forgotpassword = ({
    forgotPassword
}) => {
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const history = useHistory();
    const [ForgotData, setForgotData] = useState({
        userId: '',
        groupName: ''
    })
    const validator = useRef(new SimpleReactValidator());

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    const submitForgot = (e) => {
        e.preventDefault();
        setAddFormSubmit(true);
        if (validator.current.allValid()) {
            forgotPassword({
                payload: ForgotData
            })
        }
    }
    return (
        <Fragment>
            <div style={{ backgroundImage: 'url(bg_img.png)' }} className="main-wrapper-bg  ">
                <div className="auth-wrapper d-flex no-block justify-content-center 
                align-items-center">
                    <div style={{ backdropFilter: 'saturate(100%) blur(5px)' }} className="auth-box p-4 main-content rounded m-0">
                        <center><img src="logo-icon.png" height="50px" alt='Logo' /></center>
                        <div id="loginform">
                            <div className="logo">
                                <center>
                                    <h3 className="box-title mb-3 text-white">Forgot Password</h3>
                                </center>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <form className="form-horizontal  needs-validation" id="loginform">
                                        <div className="form-floating mb-3">

                                            <input className="form-control border rounded" type="text" required="" autoFocus={true} placeholder="User ID" onChange={(e) => {
                                                setForgotData({
                                                    ...ForgotData, userId: e.target.value
                                                })
                                            }} />
                                            <label
                                            ><i

                                                data-feather="user"
                                                className="feather-sm text-info fill-white me-2"
                                            ></i><span class="border-start border-info ps-3"
                                            >User ID</span
                                                ></label>
                                            {validator.current.message(
                                                'userId',
                                                ForgotData.userId,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'User Id Required',
                                                    }
                                                })}


                                        </div>

                                        <div className="form-floating mb-3">



                                            <input className="form-control border rounded" type="text" required="" placeholder="Group Short Name" onChange={(e) => {
                                                setForgotData({
                                                    ...ForgotData, groupName: e.target.value
                                                })
                                            }} />
                                            <label
                                            ><i
                                                data-feather="home"
                                                className="feather-sm text-info fill-white me-2 mt-1"
                                            ></i
                                                ><span class="border-start border-info ps-3"
                                                >Group Short Name</span
                                                ></label>
                                            {validator.current.message(
                                                'groupName',
                                                ForgotData.groupName,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Group Short Name Required',
                                                    }
                                                })}


                                        </div>



                                        <div className="col-md-12">
                                            <div className='row'>


                                                <div className="col-md-6">

                                                    <button type='button' className="

                                                        btn  btn-blue-color

                                                        d-block

                                                        w-100

                                                        waves-effect waves-light

                                                        "
                                                        onClick={submitForgot}
                                                    >

                                                        Submit

                                                    </button>

                                                </div>

                                                <div className="col-md-6">

                                                    <button type='primery' className="

                                                        btn btn-outline-primary

                                                        d-block
                                                        text-white
                                                        w-100

                                                        waves-effect waves-light

                                                        "
                                                        onClick={() => {
                                                            history.push('/login')
                                                        }}
                                                    >

                                                        Cancel

                                                    </button>

                                                </div>

                                            </div>
                                        </div>
                                    </form>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>



            </div>

        </Fragment>
    );
}

export default connect(mapStateToProps, {
    forgotPassword
})(Forgotpassword);