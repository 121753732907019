import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux';
import { Route, Switch, BrowserRouter, Link, useLocation } from 'react-router-dom';
import { Collapse, Input, Modal, Table, List, Row, Tooltip, Card, Select, Tree, Button, Checkbox } from 'antd';
import { PlayCircleOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import DataTable from "react-data-table-component";
import Swal from 'sweetalert2';
import { getViewOnlyRestrictedList, getViewOnlyRestUnitData, saverecords } from '../../Store/Action/Master/ViewOnlyRestricted';
import { filter, indexOf, merge } from 'lodash';
import { Toaster } from '../../Libs/Toaster';
const _ = require("lodash");


const mapStateToProps = (state) => ({
    viewOnlyRestList: state.ViewOnlyRestictedList.ViewOnlyRestrictedList.viewOnlyRestList,
    viewOnlyRestUnitList: state.ViewOnlyRestictedList.ViewOnlyRestrictedList.viewOnlyRestUnitList,
    viewOnlyRestCompDetails: state.ViewOnlyRestictedList.ViewOnlyRestrictedList.viewOnlyRestCompDetails,
    access_info: state.ViewOnlyRestictedList.ViewOnlyRestrictedList.access_info
})

const ViewOnlyRestricted = ({
    viewOnlyRestList,
    getViewOnlyRestrictedList,
    viewOnlyRestUnitList,
    getViewOnlyRestUnitData,
    viewOnlyRestCompDetails,
    saverecords,
    access_info
}) => {
    const { Panel } = Collapse;
    const { Option } = Select;
    const { Search } = Input;
    const location = useLocation();
    const [show, setShow] = useState(false)
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const paramid = location.pathname;
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const validator = useRef(new SimpleReactValidator());
    const [complianceTask, setComplianceTask] = useState([])
    const [branchName, setBranchName] = useState([])
    const [uniqueValue, setUniqueValue] = useState([])
    const [output, setOutput] = useState()
    const [userId, setUserId] = useState('')
    const [legalentity, setLegalentity] = useState('')
    console.log(legalentity, 'legalentity')
    const [value, setValue] = useState()
    const [temp, setTemp] = useState({
        tempData: []
    });
    const [state, setState] = useState([])
    const [data, setData] = useState([]);
    const [tempState, setTempState] = useState([])
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [complianceIds, setComplianceIds] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [existingChecked, setExistingChecked] = useState([]);
    const [existingUnits, setExistingUnits] = useState([]);
    const [all, setAll] = useState([]);
    const [selectcheckbox, Setselectcheckbox] = useState('block');
    const [unselectcheckbox, Setunselectcheckbox] = useState('none');
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [viewOnlyFilter, setViewOnlyFilter] = useState([]);

    useEffect(() => {

        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            setLegalentity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])
    console.log(legalentity, 'legalentity');

    const [filtervalue, setfiltervalue] = useState({
        Compliancetask: '',
        domain: '',
        frequency: '',
        domaindata: ''

    })
    // const HandelChange = (obj, value) => {
    //     setComplianceTask(obj)
    //     let diff = Object.keys(checkedKeys).filter(x => !obj.includes(x));
    //     for (let i = 0; i < value.length; i++) {
    //         setBranchName(value && value[i].children)
    //     }
    // };

    const HandelChange = (obj) => {
        console.log(obj, 'objobjobj');
        let allArray = [];
        if (obj.includes('all')) {
            if (viewOnlyRestUnitList && viewOnlyRestUnitList.length > 0) {
                let temp = []
                for (let i in viewOnlyRestUnitList) {
                    temp.push((viewOnlyRestUnitList[i].unit_id).toString())
                    allArray.push((viewOnlyRestUnitList[i].unit_id).toString())
                }
                setAll(temp)
                setComplianceTask(obj)
            }
            Setselectcheckbox('none');
            Setunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setComplianceTask(obj)
            setAll(temp);
            Setselectcheckbox('block');
            Setunselectcheckbox('none');
        } else {
            console.log(obj)
            if (obj.length == viewOnlyRestUnitList.length) {
                Setselectcheckbox('none');
                Setunselectcheckbox('block');
            } else {
                Setselectcheckbox('block');
                Setunselectcheckbox('none');
            }
            setAll(obj)
            setComplianceTask(obj)
        }
        setfiltervalue({
            ...filtervalue,
            domain: ''
        })
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };


    const onExpand = (newExpandedKeys) => {
        setExpandedKeys(newExpandedKeys);
        setAutoExpandParent(false);
    };

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetViewonlyRestList",
                        {}
                    ]
                }
            ]
            getViewOnlyRestrictedList({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth, show])

    useEffect(() => {

        validator.current.showMessages()
    }, [])


    useEffect(() => {
        if (all && all.length > 0) {
            const arr = all && all
            const obj = viewOnlyRestCompDetails && viewOnlyRestCompDetails[0]
            const select = (arr, obj) => arr.reduce((r, prop) => {
                obj.hasOwnProperty(prop) && (r[prop] = obj[prop]);
                return r;
            }, {});
            const output = select(arr, obj);
            setOutput(output)

        } else {
            setOutput({})
        }
        if (access_info && access_info.length > 0) {
            let tempAccessInfo = existingChecked;
            let finalKeyWithValue = [];
            let keys;
            let strOutput = [];
            keys = Object.keys(access_info[0])
            Object.values(access_info[0]).map((access_info_list, index) => {
                access_info_list.map((access_info_list_child) => {
                    finalKeyWithValue = keys[index] + "-" + access_info_list_child
                    tempAccessInfo.push(finalKeyWithValue)
                })


            })
            setExistingChecked(tempAccessInfo)
            setExpandedKeys(tempAccessInfo)
            setCheckedKeys(access_info[0])
        }
    }, [all])

    useEffect(() => {
        if (access_info && access_info != null) {
            if (access_info[0] && access_info.length > 0) {
                setExistingUnits(Object.keys(access_info[0]));
                setComplianceTask(Object.keys(access_info[0]))
                setAll(Object.keys(access_info[0]))
            }
        }
    }, [access_info])

    useEffect(() => {
        let tempArrayState = []
        let tempArray = []
        let separatedArray = [];
        let finalArrayPush = [];
        if (output) {
            Object.entries(output).forEach(([key, value]) => {

                if (value && value.length > 0) {
                    let arr = [];

                    let tempSetArray = [];
                    const pmenus = value.map(function (user) {
                        if ((arr.indexOf(user.statutory) < 0))
                            arr.push(user.statutory);
                    });
                    let totalListNew = [];
                    let countrylisttempdata = [];
                    let tempComplianceId = []
                    arr.map((pmenu, index) => {
                        let totalList = []
                        let entityArray = []
                        let tempArray = []


                        if (pmenu) {
                            {
                                value && value.map((subMenuList, childIndex) => {
                                    if (pmenu == subMenuList.statutory) {
                                        entityArray = {
                                            'level': 3,
                                            'unit_id': key,
                                            'comp_id': subMenuList.compliance_id,
                                            'key': key + "-" + subMenuList.compliance_id,
                                            'title': subMenuList.compliance_task,
                                            'domain': subMenuList.domain_id,
                                            'frequency': subMenuList.frequency_id
                                        }
                                        tempArray.push(entityArray)
                                        tempComplianceId.push(subMenuList.compliance_id)
                                    }

                                })
                            }
                        }
                        totalList = {
                            'level': 2,
                            'unit_id': key,
                            'title': pmenu,
                            'key': key + "_" + index,
                            'children': tempArray,
                            'childComplianceId': tempComplianceId,
                        }
                        countrylisttempdata.push(totalList)

                    })
                    const unit_name = viewOnlyRestUnitList.find(element => {
                        return element.unit_id == parseInt(key);
                    })
                    totalListNew = {
                        'level': 1,
                        'title': unit_name.unitname,
                        'key': key,
                        'children': countrylisttempdata,
                        'childComplianceId': tempComplianceId
                    }
                    finalArrayPush.push(totalListNew)
                }


            })
            setData(finalArrayPush)
        }

    }, [output])


    const onCheck = (checkedKeysValue) => {
        let myArray = [];
        let unit_id = '';
        let yArray = {};
        let x = [];
        let k = {}
        //let splitStr = checkedKeysValue.split("-")
        setExistingChecked(checkedKeysValue)
        let compliance_id = [];
        for (let i = 0; i < checkedKeysValue.length; i++) {

            let str = checkedKeysValue[i];

            if (str.indexOf('_') == -1) {
                if (str.indexOf('-') != -1) {
                    let arr1 = str.split('-')
                    unit_id = arr1[0]
                    if (!yArray[unit_id])
                        yArray[unit_id] = [];

                    if (yArray[unit_id].indexOf(arr1[1]) < 0)
                        yArray[unit_id].push(arr1[1]) //compliance_id.push(arr1[1])                   
                    x.push(arr1[1])
                } else {
                    //let arr1 = str.split('');
                    unit_id = str;
                    if (!yArray[unit_id])
                        yArray[unit_id] = [];
                }
            } else {
                let arr1 = str.split('_');
                unit_id = arr1[0];
                if (!yArray[unit_id])
                    yArray[unit_id] = [];

            }

        }

        myArray = yArray;
        setCheckedKeys(myArray);
    };

    const saveRecords = (e) => {
        e.preventDefault();
        let temp = checkedKeys
        let key = Object.keys(temp)
        let val = Object.values(temp)
        let k = {}
        for (let i in all) {
            for (let j in val) {
                if (all[i] == key[j]) {
                    k[all[i]] = val[i]
                }
            }
        }

        const savepayload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "SubmitViewonlyRestUserPrivileges",
                    {
                        "user_id": parseInt(userId),
                        "legal_entity_id": entityid,
                        "unit_compliances": k
                    }
                ]
            }
        ]
        const payloadbck = [

            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetViewonlyRestPrivilegeDetails",
                    {
                        "user_id": Number(userId),
                        "legal_entity_id": entityid
                    }
                ]
            }

        ]
        if (all && all.length > 0) {
            if (Object.keys(k).length > 0 && Object.keys(k).length == all.length) {
                //get Diff from two array and remove it from final array            
                // const finalArray = Object.keys(checkedKeys).filter(x => !complianceTask.includes(x));
                // if (finalArray) {
                //     finalArray.forEach(e => delete checkedKeys[e]);
                // }

                saverecords({
                    payload: savepayload,
                    paramid: paramid,
                    payloadbck: payloadbck
                })
            } else {
                Toaster.warning('Please Select any Compliance Task for selected units')
            }
        } else {
            Toaster.warning('Please Select any Units')
        }
    }
    const [uni_domain, setuni_domain] = useState();
    useEffect(() => {
        if (viewOnlyRestUnitList && viewOnlyRestUnitList.length > 0) {
            let newArray = getUnique(viewOnlyRestUnitList, 'domain_name');
            setuni_domain(newArray);
        }
    }, [viewOnlyRestUnitList])

    const frequencyfilter = () => {
        let finalArrayPush = [];
        Object.entries(output).forEach(([key, value]) => {
            let obj = {}
            if (filtervalue.frequency && filtervalue.frequency != 'all') {
                obj.frequency_id = parseInt(filtervalue.frequency)
            }
            if (filtervalue.domain) {
                obj.domain_id = parseInt(filtervalue.domain)
            }
            let compliance_task = [];
            let commonElements;
            if (filtervalue.Compliancetask) {
                const ab = value.filter(o =>
                    Object.keys(o).some(k =>
                        String(o[k])
                            .toLowerCase()
                            .includes(filtervalue.Compliancetask.toLowerCase())
                    )
                );
                compliance_task = ab.map((item => {
                    return item.compliance_task
                }))
            }

            const arrayvalue = _.filter(value, obj)

            if (compliance_task && compliance_task.length > 0) {

                commonElements = arrayvalue.filter((element) => {
                    return compliance_task.includes(element.compliance_task);
                });
            } else {
                commonElements = arrayvalue
            }


            if (commonElements && commonElements.length > 0) {
                let arr = [];

                let tempSetArray = [];
                const pmenus = commonElements.map(function (user) {
                    if ((arr.indexOf(user.statutory) < 0))
                        arr.push(user.statutory);
                });
                let totalListNew = [];
                let countrylisttempdata = [];
                let tempComplianceId = []
                arr.map((pmenu, index) => {
                    let totalList = []
                    let entityArray = []
                    let tempArray = []


                    if (pmenu) {
                        {
                            commonElements && commonElements.map((subMenuList, childIndex) => {
                                if (pmenu == subMenuList.statutory) {
                                    entityArray = {
                                        'level': 3,
                                        'unit_id': key,
                                        'comp_id': subMenuList.compliance_id,
                                        'key': key + "-" + subMenuList.compliance_id,
                                        'title': subMenuList.compliance_task,
                                        'domain': subMenuList.domain_id,
                                        'frequency': subMenuList.frequency_id
                                    }
                                    tempArray.push(entityArray)
                                    tempComplianceId.push(subMenuList.compliance_id)
                                }

                            })
                        }
                    }
                    totalList = {
                        'level': 2,
                        'unit_id': key,
                        'title': pmenu,
                        'key': key + "_" + index,
                        'children': tempArray,
                        'childComplianceId': tempComplianceId,
                    }
                    countrylisttempdata.push(totalList)

                })
                const unit_name = viewOnlyRestUnitList.find(element => {
                    return element.unit_id == parseInt(key);
                })
                totalListNew = {
                    'level': 1,
                    'title': unit_name.unitname,
                    'key': key,
                    'children': countrylisttempdata,
                    'childComplianceId': tempComplianceId
                }
                finalArrayPush.push(totalListNew)
            }
        });
        setData(finalArrayPush)

    }

    const getUnique = (arr, index) => {
        const unique = arr.map(e => e[index]).map((e, i, final) => final.indexOf(e) === i && i)
            .filter(e => arr[e]).map(e => arr[e]);
        return unique;
    }

    //  "country_ids": [1], multiple id search
    useEffect(() => {
        let userArray = [];
        let tempArray = [];
        if (legalentity) {
            viewOnlyRestList && viewOnlyRestList && viewOnlyRestList.map((userlist) => {
                const checkUserList = userlist.le_ids.includes(legalentity);
                console.log(checkUserList, 'checkUserList');
                if (checkUserList) {
                    tempArray = {
                        'user_id': userlist.user_id,
                        'emp_name': userlist.emp_name,
                        'emp_code': userlist.emp_code,
                        'user_name': userlist.user_name
                    }
                    userArray.push(tempArray)
                }
            })
            setViewOnlyFilter(userArray)

        }
    }, [legalentity, viewOnlyRestList])
    console.log(viewOnlyRestList, 'viewOnlyRestList');
    // Call this once
    let getAllKeys = (tree) => {
        let result = [];
        tree.forEach((x) => {
            console.log(x.key, 'treess');

            let childKeys = [];
            if (x.children) {
                childKeys = getAllKeys(x.children);
            }

            result.push(...[x.key, ...childKeys]);
        });

        return result;
    };

    const allKeys = getAllKeys(data);
    console.log(allKeys.length, 'allkeys')

    const onChange = () => {
        if (existingChecked.length === allKeys.length) {
            setExistingChecked([]);
        } else {
            setExistingChecked(allKeys);

            let myArraynew = [];
            let unit_idnew = '';
            let yArraynew = {};
            let xnew = [];
            let k = {}
            //let splitStr = checkedKeysValue.split("-")
            let compliance_id = [];
            for (let inew = 0; inew < allKeys.length; inew++) {

                let strnew = allKeys[inew];

                if (strnew.indexOf('_') == -1) {
                    if (strnew.indexOf('-') != -1) {
                        let arr11 = strnew.split('-')
                        unit_idnew = arr11[0]
                        if (!yArraynew[unit_idnew])
                            yArraynew[unit_idnew] = [];

                        if (yArraynew[unit_idnew].indexOf(arr11[1]) < 0)
                            yArraynew[unit_idnew].push(arr11[1]) //compliance_id.push(arr1[1])                   
                        xnew.push(arr11[1])
                    } else {
                        //let arr1 = str.split('');
                        unit_idnew = strnew;
                        if (!yArraynew[unit_idnew])
                            yArraynew[unit_idnew] = [];
                    }
                } else {
                    let arr11 = strnew.split('_');
                    unit_idnew = arr11[0];
                    if (!yArraynew[unit_idnew])
                        yArraynew[unit_idnew] = [];

                }

            }

            myArraynew = yArraynew;
            setCheckedKeys(myArraynew);


        }
    };

    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    return (
        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i>
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ color: '#1890ff' }}>Master</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: "large", fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>View Only Restricted - Privilege Settings</span>
                                </li>
                            </ol>
                        </nav>
                    </div>

                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card rounded p-3 pb-2">
                            {/* <div className="col-md-12"> */}
                            <div className="row">
                                <div className='col-lg-5'>
                                    <label htmlFor=""><b>Legal Entity</b> <span style={{ "color": "red" }}>*</span></label>
                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                        <div className="form-group">
                                            <Select style={{ marginTop: "5px", width: "100%" }}
                                                allowClear={true}
                                                size="default"
                                                placeholder="Select Legal Entity"
                                                onChange={(data, value) => {
                                                    setCurrentEntity(data)
                                                    setLegalentity(data)
                                                    setUserId('')
                                                    setShow(false)
                                                }}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                //  value={modalData.legal_entity}

                                                display="none"
                                            >
                                                {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                    return (
                                                        <Option title="" key={item.le_id}>
                                                            {item.le_name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                            {validator.current.message(
                                                'legalentity',
                                                legalentity != "null" ? legalentity : '',
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Legal Entity Required',
                                                    }
                                                })}
                                        </div> :
                                        <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>

                                    }
                                </div>
                                <div className='col-lg-5'>
                                    <label htmlFor=""><b>View Only Restricted User</b> <span style={{ "color": "red" }}>*</span></label>

                                    <Select style={{ marginTop: "5px" }}
                                        // onMouseEnter={false}
                                        labelInValue={false}
                                        allowClear={true}
                                        onFocus={false}
                                        onSelect={false}
                                        disabled={entityid == null || entityid == 'null' ? true : false}
                                        className='form-control'
                                        placeholder="Select View Only Restricted"
                                        onChange={(data, value) => {
                                            //setUserId(e.target.value)
                                            setUserId(data)
                                            setShow(false)
                                        }}
                                        showSearch
                                        value={userId || undefined}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    >
                                        {viewOnlyFilter && viewOnlyFilter.length > 0 && viewOnlyFilter.map((item) => {
                                            return (
                                                <Option title="" key={item.user_id}>
                                                    {`${item.emp_code}-${item.emp_name}`}
                                                </Option>
                                            );
                                        })}

                                    </Select>
                                    {validator.current.message(
                                        'userId',
                                        userId,
                                        ['required'],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'User Required',
                                                regex: 'Select view only restricted'
                                            }
                                        })}
                                </div>
                                {/* <div className='col-lg-2' style={{ marginTop : '25px', paddingLeft: '40px' }}>
                                        <Button
                                            onClick={() => {
                                                const payload = [
                                                    authtoken,
                                                    {
                                                        "session_token": authtoken,
                                                        "request": [
                                                            "GetViewonlyRestPrivilegeDetails",
                                                            {
                                                                "user_id": Number(userId),
                                                                "legal_entity_id": entityid
                                                            }
                                                        ]
                                                    }
                                                ]
                                                setAddFormSubmit(true);
                                                if (validator.current.allValid()) {
                                                    setShow(true)
                                                    getViewOnlyRestUnitData({
                                                        payload: payload,
                                                        paramid: paramid
                                                    })
                                                }
                                            }}
                                            type="primary"
                                            shape="round"
                                            className='addbutton'
                                            icon={<EyeOutlined />}
                                            style={{
                                                marginTop: "2px",marginLeft:'10%', background: "#0096FF", borderColor: "#0096FF"
                                            }}
                                        >
                                            Show
                                        </Button>
                                    </div> */}
                                <div className='col-lg-2' style={{ marginTop: '25px', paddingLeft: '40px' }} id={localStorage.getItem('currentTheme')}>
                                    <button
                                        onClick={() => {
                                            const payload = [
                                                authtoken,
                                                {
                                                    "session_token": authtoken,
                                                    "request": [
                                                        "GetViewonlyRestPrivilegeDetails",
                                                        {
                                                            "user_id": Number(userId),
                                                            "legal_entity_id": entityid
                                                        }
                                                    ]
                                                }
                                            ]
                                            setAddFormSubmit(true);
                                            if (validator.current.allValid()) {
                                                setShow(true)
                                                getViewOnlyRestUnitData({
                                                    payload: payload,
                                                    paramid: paramid
                                                })
                                            }
                                        }}
                                        className="button_v3 button_v3--mimas"

                                        style={{
                                            marginTop: "2px"
                                        }}
                                    >
                                       <span>Show</span> 
                                    </button>
                                </div>

                            </div>
                            {/* <div className='col-md-12' style={{ marginTop: "20px", textAlign: "center", marginLeft: "26%" }}>
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <Button
                                                onClick={() => {
                                                    const payload = [
                                                        authtoken,
                                                        {
                                                            "session_token": authtoken,
                                                            "request": [
                                                                "GetViewonlyRestPrivilegeDetails",
                                                                {
                                                                    "user_id": Number(userId),
                                                                    "legal_entity_id": entityid
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                    setAddFormSubmit(true);
                                                    if (validator.current.allValid()) {
                                                        setShow(true)
                                                        getViewOnlyRestUnitData({
                                                            payload: payload,
                                                            paramid: paramid
                                                        })
                                                    }
                                                }}
                                                type="primary"
                                                shape="round"
                                                className='addbutton'
                                                style={{
                                                    marginTop: "2px"
                                                }}
                                            >
                                                Show
                                            </Button>
                                        </div>
                                    </div>
                                </div> */}
                            {show == true ?
                                <>
                                    <div className="col-md-12" style={{ marginTop: "20px" }}>
                                        <div className="row">
                                            {/* <div className="col-md-6"> */}
                                            <div className={"form-group "+ localStorage.getItem('currentTheme')} id='only-restricted'>
                                                <Collapse accordion defaultActiveKey={['1']}>
                                                    <Panel header="Units"
                                                        key="1">

                                                        <Select
                                                            name="units"
                                                            id="units"
                                                            className='form-control'
                                                            placeholder="Select Units"

                                                            onChange={(option) => HandelChange(option)}
                                                            mode="multiple"

                                                            value={all}
                                                            maxTagCount='responsive'

                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        >
                                                            { }
                                                            <Option key="all" value="all" style={{ display: selectcheckbox }}>---SELECT ALL---</Option>
                                                            <Option key="unselect" value="unselect" style={{ display: unselectcheckbox }}>--UNSELECT ALL--</Option>
                                                            {viewOnlyRestUnitList && viewOnlyRestUnitList.length > 0 && viewOnlyRestUnitList.map((item, i) => {
                                                                return (
                                                                    <Option key={item.unit_id}>
                                                                        {item.unitname}
                                                                    </Option>
                                                                );
                                                            })}

                                                        </Select>
                                                    </Panel>
                                                </Collapse>
                                            </div>

                                        </div>
                                    </div>

                                    <br />

                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className={"form-group " + localStorage.getItem('currentTheme')} id='only-restricted'>
                                                <Collapse accordion defaultActiveKey={['1']}>
                                                    <Panel header="Compliance Task"
                                                        key="1">
                                                        <div className="d-flex justify-content-between" style={{ marginBottom: '25px' }}>
                                                            <Input
                                                                type="text"
                                                                className='form-control'
                                                                autoComplete={'off'}
                                                                placeholder="Enter Compliance Task"
                                                                style={{ width: '300px', height: '32px' }}
                                                                onChange={(e) => {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        Compliancetask: e.target.value
                                                                    })
                                                                }} />&nbsp;&nbsp;
                                                            <div className='d-flex'>
                                                                <label style={{ marginTop: "5px" }}><b>Domain</b></label>&nbsp;&nbsp;
                                                                <Select
                                                                    disabled={all == "" ? true : false}
                                                                    labelInValue={false}
                                                                    allowClear={false}
                                                                    onFocus={false}
                                                                    onSelect={false}
                                                                    value={filtervalue.domain || undefined}
                                                                    className='form-control'
                                                                    style={{ width: '230px', height: '32px' }}
                                                                    placeholder="Select Domain"
                                                                    defaultValue={'Select All'}
                                                                    onChange={(e) => {
                                                                        setfiltervalue({
                                                                            ...filtervalue,
                                                                            domain: e
                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                                                    <Option key={0}>Select All</Option>
                                                                    {uni_domain && uni_domain.length > 0 && uni_domain.map((data => {
                                                                        return (
                                                                            <Option key={1} value={data.domain_id}>{data.domain_name}</Option>
                                                                        )
                                                                    }))}
                                                                </Select>
                                                            </div>&nbsp;&nbsp;
                                                            <div className="d-flex">
                                                                <label htmlFor="" style={{ marginTop: "5px" }}><b>Frequency</b></label>&nbsp;&nbsp;
                                                                <Select
                                                                    labelInValue={false}
                                                                    allowClear={false}
                                                                    onFocus={false}
                                                                    onSelect={false}
                                                                    style={{ width: '170px', height: '32px' }}
                                                                    className='form-control'
                                                                    placeholder="Select Frequency"
                                                                    defaultValue={'All'}
                                                                    onChange={(e) => {
                                                                        setfiltervalue({
                                                                            ...filtervalue,
                                                                            frequency: e
                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                >
                                                                    <Option key={1} value="all">All</Option>
                                                                    <Option key={2} value='1'>One Time</Option>
                                                                    <Option key={3} value='2'>Periodical</Option>
                                                                    <Option key={4} value='3'>Review</Option>
                                                                    <Option key={5} value='4'>Flexi Review</Option>
                                                                    <Option key={6} value='5'>On Occurrence</Option>
                                                                </Select>
                                                            </div>
                                                            <div  id={localStorage.getItem('currentTheme')} style={{ 'margin-top': '-6px', 'paddingRight': '12px', paddingLeft: '3px', paddingTop: '6px' }} >
                                                                <button  
                                                                   className="button_v3 button_v3--mimas"
                                                                    onClick={frequencyfilter}>
                                                                   <span>Search</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {all.length > 0 ?
                                                            <Checkbox
                                                                onChange={onChange}
                                                                checked={existingChecked.length == allKeys.length ? true : false}
                                                            >
                                                                Select All
                                                            </Checkbox>
                                                            : ''}
                                                        <Tree
                                                            checkable
                                                            onCheck={onCheck}
                                                            //onExpand={onExpand}
                                                            //expandedKeys={expandedKeys}
                                                            checkedKeys={existingChecked}
                                                            //autoExpandParent={autoExpandParent}
                                                            treeData={data}
                                                            height={233}
                                                        />
                                                    </Panel>
                                                </Collapse>
                                            </div>
                                        </div>
                                    </div>

                                    {/* </Card> */}
                                    <div className="col-md-12" style={{ marginTop: "8px", textAlign: "center" }}>
                                        <div className="row">
                                            <div>
                                                {/* <Button
                                                        onClick={saveRecords}
                                                        type="primary"
                                                        shape="round"
                                                        className='addbutton'
                                                        style={{ background: "#32a852", borderColor: "#32a852", width: "100px", marginLeft: "90px" }}
                                                    >
                                                        Submit
                                                    </Button> */}
                                                {/* <Button type="primary" shape="round" className='addbutton'
                                                        style={{ background: "#198754", borderColor: "#198754" }}
                                                        icon={<PlayCircleOutlined />} size='default'
                                                        onClick={saveRecords}>
                                                        Submit
                                                    </Button> */}
                                                {/* <div class="button2" id={localStorage.getItem('currentTheme')} onClick={saveRecords}>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    Submit
                                                </div> */}
                                                <div id={localStorage.getItem('currentTheme')}>
                                                    <button className="button_v3 button_v3--mimas" onClick={saveRecords} ><span >Submit</span></button>

                                                </div>
                                            </div>


                                            {/* <button className="btn btn-light-primary text-primary btn-xs px-3 fs-3"
                                            style={{
                                                position: 'absolute',
                                                right: '8px',
                                                bottom: '6px'
                                            }}
                                            onClick={saveRecords}>Save Records</button> */}


                                        </div>
                                    </div>
                                </>
                                : ""}
                        </div>

                    </div>
                </div>
            </div>
        </div >
    )
}

export default connect(mapStateToProps, {
    getViewOnlyRestrictedList,
    getViewOnlyRestUnitData,
    saverecords
})(ViewOnlyRestricted)
