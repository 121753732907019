import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import SimpleReactValidator from "simple-react-validator";
import { Link, Redirect, useHistory } from "react-router-dom";
import { login } from "./../Store/Action/Login";
import { FastForwardOutlined } from "@ant-design/icons";
const mapStateToProps = (state) => ({});

const Login2 = ({ login }) => {
  const [pwd, setPwd] = useState('');
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const history = useHistory();
  const [Auth, SetAuth] = useState(false);
  const [AddFormSubmit, setAddFormSubmit] = useState(false);
  const [captchaError, SetcaptchaError] = useState(false);
  const [LoginData, setLoginData] = useState({
    userName: "",
    password: "",
    groupName: "",
    captcha: "",
  });
  const [message, setMessage] = useState("");
  const [txt, setTxt] = useState("");
  const validator = useRef(new SimpleReactValidator());

  useEffect(() => {
    loadCaptchaEnginge(4, "white", "black", "numbers");
  }, []);

  useEffect(() => {
    validator.current.showMessages();
  }, []);

  useEffect(() => {
    SetAuth(localStorage.getItem("isAuthenticated"));
  }, []);

  const submitLogin = (e) => {
    e.preventDefault();
    setAddFormSubmit(true);
    SetcaptchaError(false);
    if (validateCaptcha(LoginData.captcha) === true) {
      if (validator.current.allValid()) {
        login({
          payload: LoginData,
          navigate: history,
        });

        // e.target.reset();
        // setTxt(e.target.reset())
      }
    } else {
      SetcaptchaError(true);
    }
  };

  const onInputChange = (e) => {
    const { value } = e.target;

    const re = /^[0-9a-z]+$/;
    if (value === "" || re.test(value)) {
      setLoginData({
        ...LoginData,
        groupName: e.target.value,
      });
      setTxt(value);
    }
  };

  return Auth && Auth ? (
    <Redirect to="/home" />
  ) : (
    <Fragment>
      <body style={{ backgroundImage: 'url(bg_img.png)', height: '100vh', backgroundSize: 'cover' }}>
        <div className="main-wrapper">
          <div className="row main-content ">


            <div
              id="loginform"
              className="
        col-12
        d-flex
        align-items-center
        justify-content-center
      "
            >

              <div className="row justify-content-center w-100 mt-4 mt-lg-0">
                <div className="col-12">
                  <div className="card border-0 m-0 bg-transparent" >
                    <div className="card-body p-0 ">
                      <center><img className="mt-3" src="logo-icon.png" height="50px" alt='Logo' />
                        <h2 className="text-white">Login</h2>
                      </center>
                      <form
                        className="form-horizontal  needs-validation"
                        onSubmit={submitLogin}
                      >
                        <div className="form-floating mb-3">

                          <input

                            className="form-control border rounded"
                            autoFocus
                            type="text"
                            required=""
                            placeholder="Username"
                            onChange={(e) => {
                              setLoginData({
                                ...LoginData,
                                userName: e.target.value,
                              });
                            }}
                          />
                          <i
                            style={{ color: '#1CBDEA', position: 'absolute', top: '18px', right: '17px', fontSize: '1.5rem' }}
                            className="fas fa-user"
                          />
                          <label
                          ><span
                          >Username</span
                            ></label>
                          {validator.current.message(
                            "userName",
                            LoginData.userName,
                            ["required", `max:50`],
                            {
                              className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                                }`,
                              messages: {
                                required: "Enter Username",
                                max: "Maximum character is 50 only",
                              },
                            }
                          )}

                        </div>

                        <div className="form-floating mb-3">

                          <input
                            name="pwd"
                            className="form-control border rounded"
                            type={isRevealPwd ? 'text' : 'password'}
                            value={pwd}
                            required=""
                            placeholder="Password"
                            onChange={(e) => {
                              setPwd(e.target.value)
                              setLoginData({
                                ...LoginData,
                                password: e.target.value,

                              });
                            }}
                          />
                          <i
                            style={{ color: '#1CBDEA', position: 'absolute', top: '18px', right: '17px', fontSize: '1.5rem' }}
                            class="fas fa-eye"
                            className={isRevealPwd ? 'fas fa-eye' : 'fas fa-eye-slash'}
                            onClick={() => setIsRevealPwd((prevState) => !prevState)}
                          />


                          <label
                          ><span
                          >Password</span
                            ></label>
                          {validator.current.message(
                            "password",
                            LoginData.password,
                            ["required", `max:20`],
                            {
                              className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                                }`,
                              messages: {
                                required: "Enter Password",
                                max: "Maximum character is 20 only",
                              },
                            }
                          )}

                        </div>

                        <div className="form-floating mb-3">

                          <input
                            className="form-control border rounded"
                            type="text"
                            placeholder="Group Short Name"
                            value={txt}
                            onChange={onInputChange}
                            required=""
                          />
                          <i
                            style={{ color: '#1CBDEA', position: 'absolute', top: '18px', right: '17px', fontSize: '1.5rem' }}
                            className="fas fa-home"
                          />
                          <label
                          ><span
                          >Group Short Name</span
                            ></label>
                          {validator.current.message(
                            "groupName",
                            LoginData.groupName,
                            ["required", `max:50`],
                            {
                              className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                                }`,
                              messages: {
                                required: "Enter Group Short Name",
                                max: "Maximum character is 50 only",
                              },
                            }
                          )}

                        </div>

                        <div className="form-floating mb-3 row mx-1">

                          <div className="col-3 mt-6 px-0">
                            <LoadCanvasTemplate />
                          </div>

                          <div className="col px-0">
                            <input
                              className="form-control border rounded py-3"
                              placeholder="Enter Captcha"
                              maxLength={4}
                              onChange={(e) => {
                                setLoginData({
                                  ...LoginData,
                                  captcha: e.target.value,
                                });
                              }}
                            />

                            {validator.current.message(
                              "groupName",
                              LoginData.captcha,
                              "required",
                              {
                                className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                                  }`,
                                messages: {
                                  required: "Enter Captcha",
                                },
                              }
                            )}
                          </div>
                        </div>
                        {LoginData.captcha != "" && captchaError == true ? (
                          <span style={{ color: "red" }}>Invalid Captcha</span>
                        ) : null}
                        <div className="form-group mb-3 text-center">

                          <button
                            type="submit"
                            className="btn btn-blue-color btn-lg px-4 w-50 me-3 shadow-lg"
                            onClick={submitLogin}
                          >

                            Login
                          </button>


                          <div className="ms-auto mt-2">
                            <Link
                              to="/Forgotpassword2"
                              id="to"
                              className="fw-bold text-white"
                            >
                              Forgot Password?
                            </Link>
                          </div>
                        </div>


                      </form>

                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>

          <footer className="footer bg-transparent  pt-0 pb-0 text-white">
            2022© Aparajitha Software Services Private Limited
          </footer>
          <footer className="footer bg-transparent  pb-1 pt-2 text-white">
            <span className="ms-4">
              Privacy Policy · Terms & Condition{" "}
            </span>
          </footer>
        </div>
      </body>
    </Fragment>
  );
};

export default connect(mapStateToProps, {
  login,
})(Login2);