import { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Select } from 'antd';
import { Link } from 'react-router-dom';
import { Area, Line } from '@ant-design/plots';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";



const mapStateToProps = (state) => ({

})
const LandingPage = ({
}) => {
    const { Option } = Select;
    const data =
        [
            { "Year": "2022", "date": 'Jan', "value": 1390 },
            { "Year": "2022", "date": 'Feb', "value": 1469 },
            { "Year": "2022", "date": 'Mar', "value": 1521 },
            { "Year": "2022", "date": 'Apr', "value": 1615 },
            { "Year": "2022", "date": 'May', "value": 1703 },
            { "Year": "2022", "date": 'Jun', "value": 1767 },
            { "Year": "2022", "date": 'Jul', "value": 1806 },
            { "Year": "2022", "date": 'Aug', "value": 1903 },
            { "Year": "2022", "date": 'Sep', "value": 1986 },
            { "Year": "2022", "date": 'Oct', "value": 1952 },
            { "Year": "2022", "date": 'Nov', "value": 1910 },
            { "Year": "2022", "date": 'Dec', "value": 2015 },
            { "Year": "2023", "date": 'Jan', "value": 1000 },
            { "Year": "2023", "date": 'Feb', "value": 1100 },
            { "Year": "2023", "date": 'Mar', "value": 1200 },
            { "Year": "2023", "date": 'Apr', "value": 1300 },
            { "Year": "2023", "date": 'May', "value": 1541 },
            { "Year": "2023", "date": 'Jun', "value": 1364 },
            { "Year": "2023", "date": 'Jul', "value": 3541 },
            { "Year": "2023", "date": 'Aug', "value": 1955 },
            { "Year": "2023", "date": 'Sep', "value": 5410 },
            { "Year": "2023", "date": 'Oct', "value": 1345 },
            { "Year": "2023", "date": 'Nov', "value": 2052 },
            { "Year": "2023", "date": 'Dec', "value": 3546 },
        ]
    const dataline =
        [
            {
                "year": "Jan",
                "value": 30,
                "category": "Complied"
            },
            {
                "year": "Jan",
                "value": 54,
                "category": "Delayed Compliance"
            },
            {
                "year": "Jan",
                "value": 90,
                "category": "Not Complied"
            },
            {
                "year": "Feb",
                "value": 110,
                "category": "Complied"
            },
            {
                "year": "Feb",
                "value": 94,
                "category": "Delayed Compliance"
            },
            {
                "year": "Feb",
                "value": 80,
                "category": "Not Complied"
            },
            {
                "year": "Mar",
                "value": 75,
                "category": "Complied"
            },
            {
                "year": "Mar",
                "value": 45,
                "category": "Delayed Compliance"
            },
            {
                "year": "Mar",
                "value": 56,
                "category": "Not Complied"
            },
            {
                "year": "Apr",
                "value": 40,
                "category": "Complied"
            },
            {
                "year": "Apr",
                "value": 44,
                "category": "Delayed Compliance"
            },
            {
                "year": "Apr",
                "value": 64,
                "category": "Not Complied"
            },
            {
                "year": "May",
                "value": 65,
                "category": "Complied"
            },
            {
                "year": "May",
                "value": 75,
                "category": "Delayed Compliance"
            },
            {
                "year": "May",
                "value": 65,
                "category": "Not Complied"
            },
            {
                "year": "Jun",
                "value": 74,
                "category": "Complied"
            },
            {
                "year": "Jun",
                "value": 54,
                "category": "Delayed Compliance"
            },
            {
                "year": "Jun",
                "value": 90,
                "category": "Not Complied"
            },
            {
                "year": "Jul",
                "value": 80,
                "category": "Complied"
            },
            {
                "year": "Jul",
                "value": 70,
                "category": "Delayed Compliance"
            },
            {
                "year": "Jul",
                "value": 80,
                "category": "Not Complied"
            },
            {
                "year": "Aug",
                "value": 75,
                "category": "Complied"
            },
            {
                "year": "Aug",
                "value": 46,
                "category": "Delayed Compliance"
            },
            {
                "year": "Aug",
                "value": 90,
                "category": "Not Complied"
            },
            {
                "year": "Sep",
                "value": 65,
                "category": "Complied"
            },
            {
                "year": "Sep",
                "value": 54,
                "category": "Delayed Compliance"
            },
            {
                "year": "Sep",
                "value": 84,
                "category": "Not Complied"
            },
            {
                "year": "Oct",
                "value": 76,
                "category": "Complied"
            },
            {
                "year": "Oct",
                "value": 45,
                "category": "Delayed Compliance"
            },
            {
                "year": "Oct",
                "value": 96,
                "category": "Not Complied"
            },
            {
                "year": "Nov",
                "value": 60,
                "category": "Complied"
            },
            {
                "year": "Nov",
                "value": 74,
                "category": "Delayed Compliance"
            },
            {
                "year": "Nov",
                "value": 80,
                "category": "Not Complied"
            },
            {
                "year": "Dec",
                "value": 110,
                "category": "Complied"
            },
            {
                "year": "Dec",
                "value": 90,
                "category": "Delayed Compliance"
            },
            {
                "year": "Dec",
                "value": 70,
                "category": "Not Complied"
            },

        ]
    const configs = {
        data: data,
        xField: 'date',
        yField: 'value',
        seriesField: 'Year',
        legend: {
            position: 'bottom'
        }
    };

    const configline = {
        data: dataline,
        xField: 'year',
        yField: 'value',
        seriesField: 'category',
        yAxis: {
            label: {
                formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        color: ['#1979C9', '#D62A0D', '#FAA219'],
        legend: {
            position: 'bottom',
        },
        smooth: false,

    };

    useEffect(() => {
        am4core.useTheme(am4themes_animated);
        //overduechart
        var chart = am4core.create("chartdiv", am4charts.PieChart3D);
        chart.data = [
            {
                country: "0-30 Days",
                value: '15.17%',
                color: am4core.color('#81E475')
            },
            {
                country: "31-60 Days",
                value: '35.08%',
                color: am4core.color('#2E9BF6')
            },
            {
                country: "61-90 Days",
                value: '40.56%',
                color: am4core.color('#F62E4F')
            },
            {
                country: "Above 90 Days",
                value: '61.6%',
                color: am4core.color('#F6E42E')
            },

        ];
        chart.radius = am4core.percent(60);
        chart.innerRadius = am4core.percent(40);
        chart.startAngle = 0;
        chart.endAngle = 360;

        var series = chart.series.push(new am4charts.PieSeries3D());
        series.dataFields.value = "value";
        series.dataFields.category = "country";
        series.slices.template.propertyFields.fill = 'color'

        series.slices.template.cornerRadius = 10;
        series.slices.template.innerCornerRadius = 7;
        series.slices.template.draggable = true;
        series.slices.template.inert = true;
        series.alignLabels = true;
        chart.legend = new am4charts.Legend();


        //TaskDistribution
        var Taskchart = am4core.create("taskchartdiv", am4charts.XYChart3D);
        Taskchart.data = [{
            "year": 'Assigned',
            "income": 370,
            color: Taskchart.colors.getIndex(13),
        }, {
            "year": 'Not Opted',
            "income": 308,
            color: Taskchart.colors.getIndex(8),
        }, {
            "year": 'Unassigned',
            "income": 500,
            color: Taskchart.colors.getIndex(9),
        }, {
            "year": 'Not Applicable',
            "income": 436,
            color: Taskchart.colors.getIndex(10),
        }];
        var categoryAxis = Taskchart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "year";


        categoryAxis.numberFormatter.numberFormat = "#";
        categoryAxis.renderer.inversed = true;
        var valueAxis = Taskchart.xAxes.push(new am4charts.ValueAxis());

        var taskseries = Taskchart.series.push(new am4charts.ColumnSeries3D());
        taskseries.dataFields.valueX = "income";
        taskseries.dataFields.categoryY = "year";
        taskseries.name = "year";
        taskseries.columns.template.propertyFields.fill = "color";
        taskseries.columns.template.tooltipText = "{valueX}" + ' ' + "{categoryY}";
        taskseries.columns.template.column3D.stroke = am4core.color("#fff");
        taskseries.columns.template.column3D.strokeOpacity = 0.2;

        //criticality chart
        var criticalitychart = am4core.create("criticality", am4charts.SlicedChart);
        criticalitychart.paddingBottom = 30;
        criticalitychart.align = 'left'
        criticalitychart.data = [{
            "name": "High",
            "value": '31.65%',
            "color": '#19d228'
        }, {
            "name": "Medium",
            "value": '56.96%',
            "color": '#b4dd1e'
        }, {
            "name": "Low",
            "value": '11.39%',
            "color": '#f6d32b'
        }];

        var criticalityseries = criticalitychart.series.push(new am4charts.PyramidSeries());
        criticalityseries.dataFields.value = "value";
        criticalityseries.dataFields.category = "name";
        criticalityseries.slices.template.propertyFields.fill = 'color'
        criticalityseries.alignLabels = true;
        criticalityseries.valueIs = "height";
        criticalitychart.legend = new am4charts.Legend();

        //Compliance Status        
        var compliancestatus = am4core.create("compliancestatusdiv", am4charts.XYChart);
        compliancestatus.data = [{ "year": "India", "Complied": 25, "Delayed_Compliance": 35, "In_Progress": 15, "Not_Complied": 25 }, { "year": "Srilanka", "Complied": 10, "Delayed_Compliance": 15, "In_Progress": 36, "Not_Complied": 12 }, { "year": "Australia", "Complied": 32, "Delayed_Compliance": 51, "In_Progress": 16, "Not_Complied": 56 }]
        compliancestatus.colors.list = [am4core.color("#0de580"), am4core.color("#3c78d8"), am4core.color("#f9c80e"), am4core.color("#fe4365"),];
        var compliancestatuscategoryAxis = compliancestatus.yAxes.push(new am4charts.CategoryAxis());
        compliancestatuscategoryAxis.dataFields.category = "year";
        compliancestatuscategoryAxis.numberFormatter.numberFormat = "#";
        compliancestatuscategoryAxis.renderer.inversed = true;
        var compliancestatusvalueAxis = compliancestatus.xAxes.push(new am4charts.ValueAxis());
        var legend = compliancestatus.children.push(new am4charts.Legend());
        function makeSeries(name, fieldName, color) {
            var compliancestatusseries = compliancestatus.series.push(new am4charts.ColumnSeries3D());
            compliancestatusseries.dataFields.valueX = name;
            compliancestatusseries.dataFields.categoryY = "year";
            compliancestatusseries.dataFields.color = color;
            compliancestatusseries.name = fieldName;
            compliancestatusseries.stacked = true;
            compliancestatusseries.columns.template.propertyFields.fill = "color";
            compliancestatusseries.columns.template.tooltipText = "{name}, {valueX}";
            compliancestatusseries.columns.template.column3D.stroke = am4core.color("#fff");
            compliancestatusseries.columns.template.column3D.strokeOpacity = 0.2;
            legend.data.push(compliancestatusseries);
        }
        makeSeries("Complied", "Complied", "#00FF00");
        makeSeries("Delayed_Compliance", "Delayed Compliance", "#0000FF");
        makeSeries("In_Progress", "In Progress", "#FFFF00");
        makeSeries("Not_Complied", "Not Complied", "#FF0000");


        //Comprative trend
        var Comprativechart = am4core.create("Comprativechartdiv", am4charts.XYChart3D);
        Comprativechart.data = [{
            "country": "Jan",
            "year2022": 3.5,
            "year2021": 4.2
        }, {
            "country": "Feb",
            "year2022": 1.7,
            "year2021": 3.1
        }, {
            "country": "Mar",
            "year2022": 1.8,
            "year2021": 2.9
        }, {
            "country": "Apr",
            "year2022": 2.6,
            "year2021": 4.5
        }, {
            "country": "May",
            "year2022": 1.4,
            "year2021": 2.1
        }, {
            "country": "June",
            "year2022": 2.6,
            "year2021": 4.9
        }, {
            "country": "July",
            "year2022": 6.4,
            "year2021": 7.2
        }, {
            "country": "Aug",
            "year2022": 8,
            "year2021": 7.1
        }, {
            "country": "Sep",
            "year2022": 9.9,
            "year2021": 10.1
        },
        {
            "country": "Oct",
            "year2022": 5.1,
            "year2021": 7.1
        },
        {
            "country": "Nov",
            "year2022": 1.9,
            "year2021": 6.1
        },
        {
            "country": "Dec",
            "year2022": 6.9,
            "year2021": 8.1
        }];
        Comprativechart.colors.list = [am4core.color("#0de580"), am4core.color("#3c78d8")];
        Comprativechart.legend = new am4charts.Legend();
        var ComprativeAxis = Comprativechart.xAxes.push(new am4charts.CategoryAxis());
        ComprativeAxis.dataFields.category = "country";
        ComprativeAxis.renderer.grid.template.location = 0;
        ComprativeAxis.renderer.minGridDistance = 30;

        var ComprativevalueAxis = Comprativechart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "Compliance %";
        valueAxis.renderer.labels.template.adapter.add("text", function (text) {
            return text;
        });

        var Comprativeseries = Comprativechart.series.push(new am4charts.ColumnSeries3D());
        Comprativeseries.dataFields.valueY = "year2022";
        Comprativeseries.dataFields.categoryX = "country";
        Comprativeseries.name = "Year 2022";
        Comprativeseries.clustered = false;
        Comprativeseries.columns.template.tooltipText = "India{category} (2022): [bold]{valueY}[/]";
        Comprativeseries.columns.template.fillOpacity = 0.9;

        var Comprativeseries2 = Comprativechart.series.push(new am4charts.ColumnSeries3D());
        Comprativeseries2.dataFields.valueY = "year2021";
        Comprativeseries2.dataFields.categoryX = "country";
        Comprativeseries2.name = "Year 2021";
        Comprativeseries2.clustered = false;
        Comprativeseries2.columns.template.tooltipText = "India{category} (2021): [bold]{valueY}[/]";


        //Compliance trend

        var Compliancechart = am4core.create("Compliancechartdiv", am4charts.XYChart3D);
        Compliancechart.data = [{
            "country": "Jan",
            "Complied": 3.5,
            "DelayedCompliance": 4.2,
            "NotComplied": 5.2
        }, {
            "country": "Feb",
            "Complied": 1.7,
            "DelayedCompliance": 3.1,
            "NotComplied": 2.2
        }, {
            "country": "Mar",
            "Complied": 1.8,
            "DelayedCompliance": 2.9,
            "NotComplied": 4.2
        }, {
            "country": "Apr",
            "Complied": 2.6,
            "DelayedCompliance": 4.5,
            "NotComplied": 6.2
        }, {
            "country": "May",
            "Complied": 1.4,
            "DelayedCompliance": 2.1,
            "NotComplied": 3.2
        }, {
            "country": "June",
            "Complied": 2.6,
            "DelayedCompliance": 4.9,
            "NotComplied": 1.5
        }, {
            "country": "July",
            "Complied": 6.4,
            "DelayedCompliance": 7.2,
            "NotComplied": 8.5
        }, {
            "country": "Aug",
            "Complied": 8,
            "DelayedCompliance": 7.1,
            "NotComplied": 9
        }, {
            "country": "Sep",
            "Complied": 9.9,
            "DelayedCompliance": 10.1,
            "NotComplied": 8
        },
        {
            "country": "Oct",
            "Complied": 5.1,
            "DelayedCompliance": 7.1,
            "NotComplied": 6.5
        },
        {
            "country": "Nov",
            "Complied": 1.9,
            "DelayedCompliance": 6.1,
            "NotComplied": 3.2
        },
        {
            "country": "Dec",
            "Complied": 6.9,
            "DelayedCompliance": 8.1,
            "NotComplied": 5.2
        }];
        Compliancechart.colors.list = [am4core.color("#00FF7F"), am4core.color("#6A5ACD"), am4core.color("#FF0000")];
        Compliancechart.legend = new am4charts.Legend();
        var ComplianceAxis = Compliancechart.xAxes.push(new am4charts.CategoryAxis());
        ComplianceAxis.dataFields.category = "country";
        ComplianceAxis.renderer.grid.template.location = 0;
        ComplianceAxis.renderer.minGridDistance = 30;

        var ComprativevalueAxis = Compliancechart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "Compliance %";
        valueAxis.renderer.labels.template.adapter.add("text", function (text) {
            return text;
        });

        var Comprativeseries = Compliancechart.series.push(new am4charts.ColumnSeries3D());
        Comprativeseries.dataFields.valueY = "Complied";
        Comprativeseries.dataFields.categoryX = "country";
        Comprativeseries.name = "Complied";
        Comprativeseries.clustered = false;
        Comprativeseries.columns.template.tooltipText = "Complied{category}: [bold]{valueY}[/]";
        Comprativeseries.columns.template.fillOpacity = 0.9;

        var Comprativeseries2 = Compliancechart.series.push(new am4charts.ColumnSeries3D());
        Comprativeseries2.dataFields.valueY = "DelayedCompliance";
        Comprativeseries2.dataFields.categoryX = "country";
        Comprativeseries2.name = "Delayed Compliance";
        Comprativeseries2.clustered = false;
        Comprativeseries2.columns.template.tooltipText = "Delayed Compliance{category}: [bold]{valueY}[/]";

        var Comprativeseries3 = Compliancechart.series.push(new am4charts.ColumnSeries3D());
        Comprativeseries3.dataFields.valueY = "NotComplied";
        Comprativeseries3.dataFields.categoryX = "country";
        Comprativeseries3.name = "Not Complied";
        Comprativeseries3.clustered = false;
        Comprativeseries3.columns.template.tooltipText = "Not Complied{category}: [bold]{valueY}[/]";

        //Risk Chart
        var Riskchart = am4core.create("Riskchartdiv", am4charts.XYChart3D);
        Riskchart.hiddenState.properties.opacity = 0;
        Riskchart.data = [
            {
                country: "Not Opted",
                visits: 308,
            },
            {
                country: "Unassigned",
                visits: 3421,
            },
            {
                country: "Not Applicable",
                visits: 496,
            },
        ];
        Riskchart.colors.list = [am4core.color("#EC1D09"), am4core.color("#F7AD76"), am4core.color("#BAC1C2")];
        var RiskcategoryAxis = Riskchart.xAxes.push(new am4charts.CategoryAxis());
        RiskcategoryAxis.renderer.grid.template.location = 0;
        RiskcategoryAxis.dataFields.category = "country";
        RiskcategoryAxis.renderer.minGridDistance = 40;
        RiskcategoryAxis.fontSize = 11;
        RiskcategoryAxis.renderer.labels.template.dy = 5;

        var valueAxis = Riskchart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.renderer.minGridDistance = 30;
        valueAxis.renderer.baseGrid.disabled = true;


        var Riskseries = Riskchart.series.push(new am4charts.ColumnSeries3D());
        Riskseries.dataFields.categoryX = "country";
        Riskseries.dataFields.valueY = "visits";
        Riskseries.columns.template.tooltipText = "{country}" + ':' + "{valueY.value}";
        Riskseries.columns.template.tooltipY = 0;
        Riskseries.columns.template.strokeOpacity = 0;
        Riskseries.columns.template.adapter.add("fill", function (fill, target) {
            return Riskchart.colors.getIndex(target.dataItem.index);
        });

        //Exposed Risk
        var ExposedRiskchart = am4core.create("ExposedRiskchartdiv", am4charts.XYChart3D);
        ExposedRiskchart.hiddenState.properties.opacity = 0;
        ExposedRiskchart.data = [
            {
                country: "Not Complied",
                visits: 25,
            },
            {
                country: "Delayed Compliance",
                visits: 6,
            },
        ];
        ExposedRiskchart.colors.list = [am4core.color("#1F9DF5"), am4core.color("#F6A6F5")];
        var  ExposedRiskcategoryAxis = ExposedRiskchart.xAxes.push(new am4charts.CategoryAxis());
        ExposedRiskcategoryAxis.renderer.grid.template.location = 0;
        ExposedRiskcategoryAxis.dataFields.category = "country";
        ExposedRiskcategoryAxis.renderer.minGridDistance = 40;
        ExposedRiskcategoryAxis.fontSize = 11;
        ExposedRiskcategoryAxis.renderer.labels.template.dy = 5;

        var ExposedvalueAxis = ExposedRiskchart.yAxes.push(new am4charts.ValueAxis());
        ExposedvalueAxis.min = 0;
        ExposedvalueAxis.renderer.minGridDistance = 30;
        ExposedvalueAxis.renderer.baseGrid.disabled = true;


        var ExposedvalueAxisRiskseries = ExposedRiskchart.series.push(new am4charts.ColumnSeries3D());
        ExposedvalueAxisRiskseries.dataFields.categoryX = "country";
        ExposedvalueAxisRiskseries.dataFields.valueY = "visits";
        ExposedvalueAxisRiskseries.columns.template.tooltipText = "{country}" + ':' + "{valueY.value}";
        ExposedvalueAxisRiskseries.columns.template.tooltipY = 0;
        ExposedvalueAxisRiskseries.columns.template.strokeOpacity = 0;
        ExposedvalueAxisRiskseries.columns.template.adapter.add("fill", function (fill, target) {
            return ExposedRiskchart.colors.getIndex(target.dataItem.index);
        });
   
    }, [])


    return (
        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                        </nav>
                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Compliance Status</h3>
                                        </div>
                                        <div className="card-body">
                                            <div id="compliancestatusdiv" style={{ height: '400px' }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>    {/* c3-area.js */}
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">
                                                Comparitive Trend
                                            </h3>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className="card-body">
                                                    <div id="Comprativechartdiv" style={{ height: '400px' }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-7'>
                                    <div className="card">
                                        <div className='card-header'>
                                            <h3 className="card-title">
                                                Over Due Compliance
                                            </h3>
                                        </div>
                                        <div className="card-body">
                                            <div id="chartdiv" style={{ height: '400px' }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='card'>
                                        <div className='card-header'>
                                            <h5 className='card-title'>
                                                Criticality Chart
                                            </h5>
                                        </div>
                                        <div className='card-body'>
                                            <div id="criticality" style={{ height: '400px' }}></div>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="card-group">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title">
                                                    Task Distribution
                                                </h3>
                                            </div>
                                            <div className='card-body'>
                                                <div id="taskchartdiv" style={{ height: '400px' }}></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">
                                                Compliance Trend
                                            </h3>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className="card-body">
                                                    <div id="Compliancechartdiv" style={{ height: '400px' }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">
                                                <span>Risk Chart- Hidden Risk</span>
                                                <span style={{"margin-left": "380px"}}>Risk Chart- Exposed Risk</span>
                                            </h3>
                                        </div>
                                        <div className='card-body'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div id='Riskchartdiv' style={{ height: '400px' }}></div>
                                                </div>
                                                <div className='col-6'>
                                                <div id='ExposedRiskchartdiv' style={{ height: '400px' }}></div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default connect(mapStateToProps, {
})(LandingPage);